import { React, useState, useEffect, memo } from "react";

import { Button } from "bootstrap-4-react/lib/components";
import { Form, Modal } from "react-bootstrap";
import {
  resetAsync,
  setInvitationAsync,
  setInvitationResponse,
} from "../../service/slices/homeSlice";
import { toast } from "react-toastify";
import Ripples from 'react-ripples';
import { useDispatch, useSelector } from "react-redux";
function RegisterModal(props) {
  const { show, setShow } = props;
  const dispatch = useDispatch();
  const [invitationData, setInvitationData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    companyName: "",
  });
  const [errors, setErrors] = useState({
    firstName: "",
    lastName: "",
    email: "",
    companyName: "",
  });

  //   const _getInvitationResponse = useSelector(setInvitationResponse);

  const handleClose = () => {
    setErrors({});
    setInvitationData({});
    setShow(false);
  };
  const handleShow = () => setShow(true);

  const InviteValidation = () => {
    let error = false;
    const errorsDetails = {
      firstName: "",
      lastName: "",
      email: "",
      companyName: "",
    };
    if (!invitationData?.firstName?.length) {
      error = true;
      errorsDetails.firstName = "Please Enter First Name";
    }
    if (!invitationData?.lastName?.length) {
      error = true;
      errorsDetails.lastName = "Please Enter Last Name";
    }
    if (!invitationData?.email?.length) {
      error = true;
      errorsDetails.email = "Please Enter Email Address";
    }
    if (!invitationData?.companyName?.length) {
      error = true;
      errorsDetails.companyName = "Please Enter Company Name.";
    }
    setErrors({ ...errorsDetails });
    return error;
  };

  const Submit = async () => {
    const flag = InviteValidation();
    if (!flag) {
      const username =
        invitationData?.firstName + " " + invitationData?.lastName;
      dispatch(
        setInvitationAsync(
          {
            name: username,
            email: invitationData?.email,
            companyName: invitationData?.companyName,
          },
          (res) => {
            console.log(res);
            if (res?.data?.data && Object.keys(res?.data?.data)?.length > 1) {
              dispatch(resetAsync({}));
              toast.success(res?.data?.message);
              handleClose();
            } else {
              toast.error(res?.data?.message);
            }
          }
        )
      );
    } else {
      toast.error("please fill all the required details.");
    }
  };

  //   useEffect(() => {
  //     if (
  //       _getInvitationResponse?.response?.data &&
  //       Object.keys(_getInvitationResponse?.response?.data)?.length > 1
  //     ) {
  //       //   dispatch(resetAsync({}));
  //       toast.success(_getInvitationResponse?.response?.message);
  //       handleClose();
  //     } else {
  //       toast.error(_getInvitationResponse?.response?.message);
  //     }
  //   }, [_getInvitationResponse]);
  return (
    <>
      <Modal className="resgister_popup" show={show} onHide={handleClose}>
        <Modal.Body>
          <Form action="">
            <div className="row">
              <div className="col-md-6">
                <Form.Group
                  className="mb-2"
                  controlId="exampleForm.ControlInput1"
                >
                  <Form.Label>First Name</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter First Name"
                    onChange={(e) =>
                      setInvitationData({
                        ...invitationData,
                        firstName: e.target.value,
                      })
                    }
                  />
                  <div className={"text-danger"}>{errors?.firstName}</div>
                </Form.Group>
              </div>
              <div className="col-md-6">
                <Form.Group
                  className="mb-2"
                  controlId="exampleForm.ControlInput1"
                >
                  <Form.Label>Last Name</Form.Label>
                  <Form.Control
                    type="txt"
                    placeholder="Enter Last Name"
                    onChange={(e) =>
                      setInvitationData({
                        ...invitationData,
                        lastName: e.target.value,
                      })
                    }
                  />
                  <div className={"text-danger"}>{errors?.lastName}</div>
                </Form.Group>
              </div>
              <div className="col-md-6">
                <Form.Group
                  className="mb-3"
                  controlId="exampleForm.ControlInput1"
                >
                  <Form.Label>Email</Form.Label>
                  <Form.Control
                    type="email"
                    placeholder="Enter Email"
                    onChange={(e) =>
                      setInvitationData({
                        ...invitationData,
                        email: e.target.value,
                      })
                    }
                  />
                  <div className={"text-danger"}>{errors?.email}</div>
                </Form.Group>
              </div>
              <div className="col-md-6">
                <Form.Group
                  className="mb-3"
                  controlId="exampleForm.ControlInput1"
                >
                  <Form.Label>Company Name</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter Company Name"
                    onChange={(e) =>
                      setInvitationData({
                        ...invitationData,
                        companyName: e.target.value,
                      })
                    }
                  />
                  <div className={"text-danger"}>{errors?.companyName}</div>
                </Form.Group>
              </div>
              <div className="col-md-6">
              <Ripples color="#004ac340" during={1200}>
                <Button
                  className="close_btn"
                  type="button"
                  variant=""
                  onClick={handleClose}
                >
                  Cancel
                </Button>
                </Ripples>
              </div>
              <div className="col-md-6">
              <Ripples color="#ffffff47" during={1200}>
                <Button
                  className="invite_btn"
                  type="button"
                  variant=""
                  onClick={Submit}
                >
                  Invite
                </Button>
                </Ripples>
              </div>
            </div>
          </Form>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default memo(RegisterModal);
