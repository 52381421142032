import React from "react";
import mangeease from "../../assets/img/mange-ease.svg";
import warning from "../../assets/img/warning.svg";
import eye from "../../assets/img/eye.svg";
import exporticon from "../../assets/img/export.svg";
import customBranding from "../../assets/img/custom-branding.svg";
import profileucin from "../../assets/img/profileucin.svg";
import documentUpload from "../../assets/img/document-upload.svg";
import fasterExperience from "../../assets/img/faster-experience.svg";
import tickCircle from "../../assets/img/tick-circle.svg";
import { Button } from "bootstrap-4-react/lib/components";
import { Link } from "react-router-dom";


function BestServices(props) {
  const handleClick = () => {
    window.scrollTo(0, 0);
  };
  return (
    <section className={props.class}>
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <div className="myCard">
              <div className="row">
                <div className="col-md-12">
                  <h2 className="wow fadeInDown">
                  Exclusive <span>Benefits</span> for Your Cosmetics Brand
                  </h2>
                </div>
                <div className="col-md-12 col-lg-6 col-xl-6 mb-5 mb-lg-0">
                  <img className="wow fadeInUp"  data-wow-duration="1.50s" src={mangeease} />
                </div>
                <div className="col-md-12 col-lg-6 col-xl-6 contentBox">
                  <h3 className="wow fadeInUp" >
                  Enjoy Effortless <span>Management</span>
                  </h3>
                  <p className="wow fadeInUp" data-wow-duration=".50s">
                    Discover how our cutting-edge technology seamlessly safeguards your brand while elevating the customer experience.
                  </p>
                  <ul>
                    <li className="wow fadeInUp" data-wow-delay=".20s" data-wow-duration=".50s">
                      <img src={warning} />
                      <span>Counterfeit tracking with real-time data</span>
                    </li>
                    <li className="wow fadeInUp" data-wow-delay=".30s" data-wow-duration=".50s">
                      <img src={eye} />
                      <span>Visibility of used & available batches</span>
                    </li>
                    <li className="wow fadeInUp" data-wow-delay=".40s" data-wow-duration=".50s">
                      <img src={exporticon} />
                      <span>Quickly export & print QR codes in bulk</span>
                    </li>
                    <li className="wow fadeInUp" data-wow-delay=".50s" data-wow-duration=".50s">
                      <img src={customBranding} />
                      <span>Custom branding to personalize the app</span>
                    </li>
                    <li className="wow fadeInUp" data-wow-delay=".60s" data-wow-duration=".50s">
                      <img src={profileucin} />
                      <span>Manage your team & easily assign roles</span>
                    </li>
                    <li className="wow fadeInUp" data-wow-delay=".70s" data-wow-duration=".50s">
                      <img src={documentUpload} />
                      <span>Add product videos & push notifications</span>
                    </li>
                  </ul>
                  <Link to="/pricing" className="wow fadeInUp" data-wow-delay=".70s"  data-wow-duration=".50s">
                  <Button variant="" className="subscribeBtn2" onClick={handleClick}>
                  Select Your Plan{" "}
                  </Button>
                  </Link>
                </div>

                <div className="col-md-12" style={{ marginTop: "100px" }}></div>

                <div className="col-md-12 col-lg-6 col-xl-6 mt-5 mt-lg-0 contentBox order-2 order-lg-1">
                  <h3 className="wow fadeInUp">
                  Enhanced <span>Speed</span>
                  </h3>
                  <p>
                  Complete tasks with remarkable speed and efficiency, allowing you to streamline the authentication process.{" "}
                  </p>

                  <div className="tags_ui" style={{ marginBottom: "32px" }}>
                    <span className="wow fadeInUp" data-wow-delay=".10s" data-wow-duration=".50s">
                      <img src={tickCircle} />
                      4x faster load time, data reading & updates
                    </span>
                    <span className="wow fadeInUp" data-wow-delay=".20s" data-wow-duration=".50s">
                      <img src={tickCircle} />
                      Rapid batch creation & activation
                    </span>
                    <span className="wow fadeInUp" data-wow-delay=".30s" data-wow-duration=".50s">
                      <img src={tickCircle} />
                      Fetch users’ scan locations on runtime
                    </span>
                    <span className="wow fadeInUp" data-wow-delay=".40s" data-wow-duration=".50s">
                      <img src={tickCircle} />
                      Generate QR codes in seconds
                    </span>
                  </div>
                  <Link to="/pricing"  className="wow fadeInUp" data-wow-delay=".5s" data-wow-duration=".50s">
                    <Button variant="" className="subscribeBtn2" onClick={handleClick}>
                    Request for Demo
                    </Button>
                  </Link>
                </div>
                <div className="col-md-12 col-lg-6 col-xl-6 order-1 order-lg-2 text-right">
                  <img  className="wow fadeInUp" data-wow-duration="1.50s" src={fasterExperience} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default BestServices;
