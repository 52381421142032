import { createSlice } from "@reduxjs/toolkit";
import { postAPICall, getAPICall } from "../client/Methodcall";
import { config } from "../client/config";

export const FaqsSlice = createSlice({
  name: "faqs",
  initialState: {
    getFaqsResponse: {
      isLoading: false,
      response: {},
    },
  },
  reducers: {
    getFaqs: (state, action) => {
      state.getFaqsResponse = action.payload;
    },
    reset: (state, action) => {
      state.getFaqsResponse = {
        isLoading: false,
        response: {},
      };
    },
  },
});
export const resetAsync = () => async (dispatch) => {
  dispatch(reset());
};
export const getFaqsAsync = (body) => async (dispatch) => {
  try {
    dispatch(getFaqs({ isLoading: true }));
    var URL = `${process.env.REACT_APP_BASE_URL}${config.GET_FAQS}`;
    const result = await getAPICall(URL, body);
    dispatch(getFaqs({ isLoading: false, response: result.data }));
  } catch (error) {
    dispatch(getFaqs({ isLoading: false }));
  }
};

export const { getFaqs, reset } = FaqsSlice.actions;

export const getFaqsResponse = (state) => state.faqs.getFaqsResponse;

export default FaqsSlice.reducer;
