import React from "react";
import { Form } from "react-bootstrap";

function FormControl(props) {
  return (
    <div>
      <Form.Group className="" controlId="formBasicEmail">
        <Form.Label>
          {props.labels} <span>{props.sublabel}</span>
        </Form.Label>
        <Form.Control
          type={props.type}
          value={props.value ? props.value : ""}
          name={props.name}
          placeholder={props.placeholder}
          onChange={(event) => props.handleChange(event)}
          min={props?.min}
        />
      </Form.Group>
    </div>
  );
}

export default FormControl;
