import React, { useEffect, useState } from "react";
import HeroSection from "../hero/HeroSection";
import Partner from "../partners/Partner";
import BestServices from "../bestservices/BestServices";
import ClaritySolutions from "../claritysolutions/ClaritySolutions";
import ActiveUser from "../activeuser/ActiveUser";
import StartHassle from "../starthassle/StartHassle";
import Client from "../clients/Client";
import DownloadApp from "../downloadapp/DownloadApp";
import { useDispatch, useSelector } from "react-redux";
import {
  getPartnersAsync,
  getPartnersResponse,
  getUserLocationsResponse,
  getUserLocationsAsync
} from "../../service/slices/homeSlice";
import { ColorRing, TailSpin } from "react-loader-spinner";

function Home(props) {
  const [isLoading, setIsLoading] = useState(false);
  const [partnersData, setPartnersData] = useState([]);
  const [activeLocations,setActiveLocations] = useState([]);
  //  const [allActiveLocations,setAllActiveLocations]  = useState([]);
  //  const [maximumActiveUser, setMaximumActiveUser] = useState(0);
  //  const [totalActiveLocations,setTotalActiveLocations] = useState(0);
  const dispatch = useDispatch();
  const _getPartnersResponse = useSelector(getPartnersResponse);
  const _getUserLocationsResponse = useSelector(getUserLocationsResponse);
  useEffect(() => {
    setIsLoading(true);
    dispatch(
      getPartnersAsync({
        limit: 50,
      })
    );
    dispatch(
      getUserLocationsAsync()
    );
  }, []);

  useEffect(() => {
    if (_getPartnersResponse?.response?.status == 200) {
      setIsLoading(_getPartnersResponse?.isLoading);
      setPartnersData(_getPartnersResponse?.response?.data);
      
    }
  }, [_getPartnersResponse]);

  useEffect(() => {
    if (_getUserLocationsResponse?.response?.status == 200) {
      
      setIsLoading(_getUserLocationsResponse?.isLoading);
     setActiveLocations(_getUserLocationsResponse?.response?.data);
     // setAllActiveLocations(_getPartnersResponse?.response?.data?.allActiveLocations);
      // setMaximumActiveUser(_getPartnersResponse?.response?.data?.maximumActiveUser);
      // setTotalActiveLocations(_getPartnersResponse?.response?.data?.totalActiveLocations);
    }
  }, [_getUserLocationsResponse]);

  return (
    <>
    {!isLoading ? (
      <>
        <HeroSection class="herosection" />
        <Partner
          class="partnerSection"
          partnersData={partnersData}
          isLoading={isLoading}
        />
        <BestServices class="bestServices" />
        <ClaritySolutions class="claritySolution" />
        <ActiveUser activeLocations={activeLocations} class="activeUser" />
        <StartHassle class="startHassle" />
        <Client class="clientSection" />
        <DownloadApp class="downloadapp" />
      </>
    ) : (
            <div
              className=""
              style={{
                height: "100vh",
                width: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <ColorRing
                visible={true}
                height="80"
                width="80"
                ariaLabel="blocks-loading"
                wrapperStyle={{}}
                wrapperClass="blocks-wrapper"
                colors={["#004ac3", "#346fcf", "#528ae6", "#346fcf", "#004ac3"]}
              />
            </div>
          )}
    </>
  );
}

// return (
//   <>
//     {!isLoading ? (
//       <>
//         <HeroSection class="herosection" />
//         <Partner class="partnerSection" partnersData={partnersData} />
//         <BestServices class="bestServices" />
//         <ClaritySolutions class="claritySolution" />
//         <ActiveUser class="activeUser" />
//         <StartHassle class="startHassle" />
//         <Client class="clientSection" />
//         <DownloadApp class="downloadapp" />
//       </>
//     ) : (
//       <div
//         className=""
//         style={{
//           height: "100vh",
//           width: "100%",
//           display: "flex",
//           justifyContent: "center",
//           alignItems: "center",
//         }}
//       >
//         <ColorRing
//           visible={true}
//           height="80"
//           width="80"
//           ariaLabel="blocks-loading"
//           wrapperStyle={{}}
//           wrapperClass="blocks-wrapper"
//           colors={["#e15b64", "#f47e60", "#f8b26a", "#abbd81", "#849b87"]}
//         />
//       </div>
//     )}
//   </>
// );

export default Home;
