import { createSlice } from "@reduxjs/toolkit";
import { postAPICall, getAPICall } from "../client/Methodcall";
import { config } from "../client/config";

export const ActionUsSlice = createSlice({
  name: "aboutus",
  initialState: {
    getCountResponse: {
      isLoading: false,
      response: {},
    },
    getAboutProfessionalsResponse: {
      isLoading: false,
      response: {},
    },
  },
  reducers: {
    getCount: (state, action) => {
      state.getCountResponse = action.payload;
    },
    getAboutusProfessionals: (state, action) => {
      state.getAboutProfessionalsResponse = action.payload;
    },
    reset: (state, action) => {
      state.getCountResponse = {
        isLoading: false,
        response: {},
      };
    },
  },
});
export const resetAsync = () => async (dispatch) => {
  dispatch(reset());
};
export const getCountAsync = (body) => async (dispatch) => {
  try {
    dispatch(getCount({ isLoading: true }));
    var URL = `${process.env.REACT_APP_BASE_URL}${config.GET_COUNT}`;
    const result = await getAPICall(URL, body);
    dispatch(getCount({ isLoading: false, response: result.data }));
  } catch (error) {
    dispatch(getCount({ isLoading: false }));
  }
};
export const getAboutusProfessionalsAsync = (body) => async (dispatch) => {
  try {
    dispatch(getAboutusProfessionals({ isLoading: true }));
    var URL = `${process.env.REACT_APP_BASE_URL}${config.GET_ABOUT_US}`;
    const result = await getAPICall(URL, body);
    dispatch(
      getAboutusProfessionals({ isLoading: false, response: result.data })
    );
  } catch (error) {
    dispatch(getAboutusProfessionals({ isLoading: false }));
  }
};

export const { getCount, reset, getAboutusProfessionals } =
  ActionUsSlice.actions;

export const getAboutProfessionalsResponse = (state) =>
  state.aboutus.getAboutProfessionalsResponse;
export const getCountResponse = (state) => state.aboutus.getCountResponse;

export default ActionUsSlice.reducer;
