import React, { useEffect, useState } from "react";
import PlanCard from "./PlanCard";
import { Accordion, Col, Form } from "react-bootstrap";
import PlanTable from "./PlanTable";
import SideTable from "./SideTable";
import Client from "../clients/Client";
import AccordionBox from "./AccordionBox";
import DownloadApp from "../downloadapp/DownloadApp";
import Footer from "../footer/Footer";
import OtherCards from "./OtherCards";
import Carousel from "react-grid-carousel";
import { useDispatch, useSelector } from "react-redux";
import { getFaqsAsync, getFaqsResponse } from "../../service/slices/faqsSlice";
import { ColorRing } from "react-loader-spinner";
import Ripples from "react-ripples";
// import Carousel from "react-multi-carousel";
// import "react-multi-carousel/lib/styles.css";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Pagination, Navigation } from 'swiper/modules';
import logo from "../../assets/img/logo_without_text.png";

import {
  getPlanesAsync,
  getPlanesResponse,
} from "../../service/slices/planesSlice";
function PricingPurchase(props) {
  const [faqsData, setFaqsData] = useState([]);
  const [planesData, setPlanesData] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [planType, setPlanType] = useState("Monthly");
  const _getFaqsResponse = useSelector(getFaqsResponse);
  const _getPlanesResponse = useSelector(getPlanesResponse);
  const [isChecked, setIsChecked] = useState(false);
  const dispatch = useDispatch();
  const getPlanesCalling = () => {
    setIsLoading(true);
    const val = isChecked == false ? "Monthly" : "Annual";
    dispatch(
      getPlanesAsync({
        type: val,
      })
    );
  };
  useEffect(() => {
    setIsLoading(true);
    dispatch(
      getFaqsAsync({
        users: "Web",
        sortBy: "priority",
        order: "ASC",
      })
    );
    getPlanesCalling();
  }, []);
  // useEffect(() => {
  //   getPlanesCalling();
  // }, [isChecked]);
  useEffect(() => {
    if (_getFaqsResponse?.response?.status == 200) {
      // console.log(_getFaqsResponse, ":::data");
      setIsLoading(_getFaqsResponse?.isLoading);
      setFaqsData(_getFaqsResponse?.response?.data);
    }
  }, [_getFaqsResponse]);
  useEffect(() => {
    if (_getPlanesResponse?.response?.status == 200) {
      // console.log(_getPlanesResponse, ":::data planes");
      setIsLoading(_getPlanesResponse?.isLoading);
      // const initialPlan = _getPlanesResponse?.response?.data;
      // const enterpriseIndex = initialPlan.plans.findIndex(
      //   (item) => item.name === "Enterprise "
      // );

      // if (enterpriseIndex !== -1) {
      //   const updatedPlans = [
      //     ...initialPlan.plans.slice(0, enterpriseIndex),
      //     ...initialPlan.plans.slice(enterpriseIndex + 1),
      //     initialPlan.plans[enterpriseIndex],
      //   ];
      //   setPlanesData({
      //     ...initialPlan,
      //     plans: updatedPlans,
      //   });
      // }else{
      //   console.log("not found");
      // }

      setPlanesData(_getPlanesResponse?.response?.data);
    }
  }, [_getPlanesResponse]);
  const handleSwitchChange = (e) => {
    const isChecked = e.target.checked;
    setIsChecked(isChecked);
    console.log(`Switch is ${isChecked ? "on" : "off"}`);
  };

  useEffect(() => {
    if (isChecked == true) {
      // Annual
      const AnnualData = planesData?.plans?.map((plan) => {
        return {
          ...plan,
          planDuration: "Annual",
          price: Math.round(
            plan?.price * 12 -
              ((plan?.price * 12) / 100) * (planesData?.discount || 0)
          ),
        };
      });
      setPlanesData({ ...planesData, plans: AnnualData });
    } else {
      // Monthly
      if (_getFaqsResponse?.response?.status == 200) {
        setPlanesData(_getPlanesResponse?.response?.data);
      }
    }
  }, [isChecked]);

  const breakPoints = [
    {
      breakpoint: 1,
      cols: 1,
      rows: 1,
      gap: 10,
      // loop: true,
      autoplay: 1000,
    },
    {
      breakpoint: 575,
      cols: 1,
      rows: 1,
      gap: 10,
      // loop: true,
      autoplay: 1000,
      arrowLeft: true,
      nav: true,
    },
    {
      breakpoint: 768,
      cols: 1,
      rows: 1,
      gap: 10,
      // loop: true,
      autoplay: 1000,
    },
    {
      breakpoint: 1199,
      cols: 1,
      rows: 1,
      gap: 10,
      // loop: true,
      autoplay: 1000,
    },
    {
      breakpoint: 1440,
      cols: 1,
      rows: 1,
      gap: 10,
      // loop: true,
      autoplay: 1000,
    },
  ];

  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 3,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };

  return (
    <>
      {!isLoading ? (
        <section className={props.class}>
          <div className="container" style={{overflow: "hidden"}}>
            <div className="row">
              <div className="col-md-12 text-center">
                <h1>
                Get Clarity on Counterfeit <span>Products</span>
                </h1>
                <p className="caption">
                Become a Clarity Client: Select a subscription that suits your needs.
                </p>
              </div>
            </div>

            <div className="row">
              <div className="col-md-12">
                <div className="switch_box">
                  <p>Monthly</p>
                  <Form>
                    <Form.Check
                      type="switch"
                      id="custom-switch"
                      label=""
                      onChange={handleSwitchChange}
                      checked={isChecked}
                    />
                  </Form>
                  <p>
                    Yearly <span>{planesData?.discount || 0}% OFF</span>
                  </p>
                </div>
              </div>
            </div>

            {/* <div className="row"> */}
              {/* <Carousel
                cols={3}
                rows={1}
                breakPoints={breakPoints}
                gap={32}
                showDots={true}
                // loop
                autoplay={true}
              > */}



                {/* <Carousel
              responsive={responsive}
              // shouldResetAutoplay={true}
              rewind={true}
              rewindWithAnimation={true}
              showDots={true}
              autoPlay={true}
              autoPlaySpeed={3000}
              // infinite={true}
              // swipeable={true}
              draggable={false}
              arrows={false}
            > */}









{/* 
                {planesData?.plans?.map((plan) => {
                  return (
                    <Carousel.Item>
                      <PlanCard
                        planname="Free"
                        price="0"
                        class="custom_card"
                        // class="custom_card wow fadeInRight"
                        planeData={plan}
                        maxPurchaseCountPlanId={maxPurchaseCountPlanId}
                      />
                    </Carousel.Item>
                  );
                })}
              </Carousel> */}
            {/* </div> */}

            <div className="row">
         
              <Swiper
                slidesPerView={3}
                spaceBetween={30}
                // loop={true}
                autoplay={{
                  delay: 4000,
                  disableOnInteraction: false,
                  pauseOnMouseEnter: true,
                }}
                pagination={{
                  clickable: true,
                }}
                modules={[Autoplay, Pagination, Navigation]}
                className="mySwiper"
              >

{planesData?.plans?.map((plan) => {
                  return (
                    <SwiperSlide>
                      <PlanCard
                        planname="Free"
                        price="0"
                        class="custom_card wow fadeInRight"
                        planeData={plan}
                      />
                    </SwiperSlide>
                  );
                })}
              </Swiper>
            </div>

            {/* <div className="row">
            <div className="col-md-12">
              <Accordion defaultActiveKey="0">
                <Accordion.Item eventKey="0">
                  <Accordion.Header>Accordion Item #1</Accordion.Header>
                  <Accordion.Body>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                    do eiusmod tempor incididunt ut labore et dolore magna
                    aliqua. Ut enim ad minim veniam, quis nostrud exercitation
                    ullamco laboris nisi ut aliquip ex ea commodo consequat.
                    Duis aute irure dolor in reprehenderit in voluptate velit
                    esse cillum dolore eu fugiat nulla pariatur. Excepteur sint
                    occaecat cupidatat non proident, sunt in culpa qui officia
                    deserunt mollit anim id est laborum.
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
            </div>
          </div> */}

            <Accordion
              defaultActiveKey="0"
              className="customAccordian"
              style={{ marginTop: "150px" }}
            >
              <Accordion.Item eventKey="0">
                <Accordion.Header>
                  <span className="text">Hide</span>{" "}
                  <span className="text">Show</span> Comparison
                </Accordion.Header>
                <Accordion.Body>
                  {planesData?.plans?.length ? (
                    <div className="row">
                      <div className="col-md-12">
                        {/* <h1 style={{ marginTop: "168px", marginBottom: "64px" }}>
                Start you business with <span>Clarity</span>
              </h1> */}
                      </div>
                      <div className="col-md-3">
                        <SideTable />
                      </div>
                      <div className="col-md-9">
                        <PlanTable class="PlanTable" plansData={planesData} />
                      </div>
                    </div>
                  ) : (
                    ""
                  )}
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>

            <div className="row" style={{ marginTop: "96px" }}>
              <OtherCards />
            </div>
            <div className="row">
              <div
                className="col-md-12 text-center"
                style={{ marginTop: "120px", marginBottom: "64px" }}
              >
                <h1
                  className="wow fadeInUp"
                  data-wow-duration=".50s"
                  data-wow-delay=".30s"
                >
                  Frequently Asked <span>Questions</span>
                </h1>
                <p
                  className="caption wow fadeInUp"
                  data-wow-duration=".50s"
                  data-wow-delay=".50s"
                >
                  Everything You Need to Know About Our Services, Policies, Customization, 
                  Support, and Payment Options.
                </p>
              </div>

              <div className="col-md-12 wow fadeInUp" data-wow-duration="1s">
                <AccordionBox faqsData={faqsData} />
              </div>
            </div>
          </div>
        </section>
      ) : (
        <div
          className=""
          style={{
            height: "100vh",
            width: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <ColorRing
            visible={true}
            height="80"
            width="80"
            ariaLabel="blocks-loading"
            wrapperStyle={{}}
            wrapperClass="blocks-wrapper"
            colors={["#004ac3", "#346fcf", "#528ae6", "#346fcf", "#004ac3"]}
          />
        </div>
        
      )}
      {/* <div
                className=""
                style={{
                  marginTop: "200px",
                  height: "300px",
                  width: "100%",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <div>
                  <img
                    style={{ height: "30px", width: "40px" }}
                    src={logo}
                    alt="logo"
                  />
                </div>
                <div class="three col">
                  <div class="loader" id="loader-4">
                    <span></span>
                    <span></span>
                    <span></span>
                  </div>
                </div>
              </div> */}
      {/* <Footer class="footer" /> */}
    </>
  );
}

export default PricingPurchase;
