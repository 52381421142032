import { createSlice } from "@reduxjs/toolkit";
import { postAPICall, getAPICall } from "../client/Methodcall";
import { config } from "../client/config";

export const TeamsConditionSlice = createSlice({
  name: "teamscondition",
  initialState: {
    getTeamsConditionResponse: {
      isLoading: false,
      response: {},
    },
    getPrivacyPolicyResponse: {
      isLoading: false,
      response: {},
    },
  },
  reducers: {
    getTeamsCondition: (state, action) => {
      state.getTeamsConditionResponse = action.payload;
    },
    getPrivacyPolicy: (state, action) => {
      state.getPrivacyPolicyResponse = action.payload;
    },
    reset: (state, action) => {
      state.getTeamsConditionResponse = {
        isLoading: false,
        response: {},
      };
    },
  },
});
export const resetAsync = () => async (dispatch) => {
  dispatch(reset());
};
export const getTeamsConditionAsync = (body, callback) => async (dispatch) => {
  try {
    dispatch(getTeamsCondition({ isLoading: true }));
    var URL = `${process.env.REACT_APP_BASE_URL}${config.GET_TEAMS_CONDITION}`;
    const result = await getAPICall(URL, body).then((res) => {
      callback(res);
      return res;
    });
    dispatch(getTeamsCondition({ isLoading: false, response: result.data }));
  } catch (error) {
    dispatch(getTeamsCondition({ isLoading: false }));
  }
};

export const getPrivacyPolicyAsync = (body, callback) => async (dispatch) => {
  try {
    dispatch(getPrivacyPolicy({ isLoading: true }));
    var URL = `${process.env.REACT_APP_BASE_URL}${config.GET_PRIVACY_POLICY}`;
    const result = await getAPICall(URL, body).then((res) => {
      callback(res);
      return res;
    });
    dispatch(getPrivacyPolicy({ isLoading: false, response: result.data }));
  } catch (error) {
    dispatch(getPrivacyPolicy({ isLoading: false }));
  }
};

export const { getPrivacyPolicy, getTeamsCondition, reset } =
  TeamsConditionSlice.actions;

export const getTeamsConditionResponse = (state) =>
  state.teamscondition.getTeamsConditionResponse;

export const getPrivacyPolicyResponse = (state) =>
  state.teamscondition.getPrivacyPolicyResponse;

export default TeamsConditionSlice.reducer;
