export const getAPICall = async (endPoint, params, access_token = "") => {
  let url = new URL(endPoint);
  if (params) {
    Object.keys(params).forEach((key) =>
      url.searchParams.append(key, params[key])
    );
  }

  let response = await fetch(url, {
    method: "GET",
    headers: {
      // "Access-Control-Allow-Origin": "*",
      // "ngrok-skip-browser-warning": "true",
    },
  });

  let data = await response.json();
  return { data: data };
};

export const postAPICall = async (url, params, access_token = "") => {
  const accessToken = localStorage.getItem("access_token")
    ? localStorage.getItem("access_token")
    : access_token;

  var myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/x-www-form-urlencoded ");
  myHeaders.append("Authorization", "Bearer " + accessToken);
  var urlencoded = new URLSearchParams();
  if (params) {
    Object.keys(params).forEach((key) => urlencoded.append(key, params[key]));
  }
  var requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: urlencoded,
    redirect: "follow",
  };
  let response = await fetch(url, requestOptions);
  let data = await response.json();

  return { data: data };
};

export const postFileAPICall = async (url, params, access_token = "") => {
  var myHeaders = new Headers();
  const accessToken = localStorage.getItem("access_token")
    ? localStorage.getItem("access_token")
    : access_token;
  var formdata = new FormData();
  for (const key in params) {
    formdata.append(key, params[key]);
  }
  var requestOptions = {
    method: "POST",
    headers: {
      Authorization: "Bearer " + accessToken,
    },
    body: formdata,
    redirect: "follow",
  };
  let response = await fetch(url, requestOptions);
  let data = await response.json();
  return { data: data };
};
