import React from "react";
import help_support from "../../assets/img/help_support.svg";
import account_manage from "../../assets/img/account_manage.svg";
import technical_support from "../../assets/img/technical_support.svg";
import Ripples from "react-ripples";
import { Link } from "react-router-dom";

function HelpSupport(props) {
  return (
    <section className="help_support">
      <div className="container">
        <div className="row row_one">
          <div className="col-sm-12 col-md-12 col-lg-6 col-xl-6">
            <h2 className="wow fadeInLeft" data-wow-duration="1s">
              Guiding You <span>Every Step</span> of the Way
            </h2>
            <p className="wow fadeInLeft"  data-wow-duration="1s" data-wow-delay=".20s">
              Comprehensive Support: Expert Guidance Every Step, Ensuring Your
              Success Through Challenges and Milestones Together.
            </p>
          </div>
          <div className="col-sm-12 col-md-10 col-lg-6 col-xl-6 offset-0 offset-md-1 offset-lg-0 text-right">
            <img className="wow fadeInRight"  data-wow-duration="1s" src={help_support} />
          </div>

          <div className="col-md-12">
            <h3>
              Choose where you want <span>support</span>
            </h3>
          </div>

          <div className="col-md-6">
            <div className="supportCard wow fadeInLeft"  data-wow-duration=".70s">
              <div
                className="d-flex align-items-center"
                style={{ marginBottom: "24px" }}
              >
                <img src={account_manage} />
                <h4>Account Management</h4>
              </div>
              <p>
                Are you confused about managing your account? You can check here
                for assistance.
              </p>
              <Ripples color="#ffffff47" during={1200}>
                <Link to={"/account-management"}>
                  <button>Learn more</button>
                </Link>
              </Ripples>
            </div>
          </div>

          <div className="col-md-6">
            <div className="supportCard wow fadeInRight"  data-wow-duration=".70s">
              <div
                className="d-flex align-items-center"
                style={{ marginBottom: "24px" }}
              >
                <img src={technical_support} />
                <h4>Technical support</h4>
              </div>
              <p>
                If you're ever unsure, you can watch video tutorials to easily
                understand and get the help
              </p>
              <Ripples color="#ffffff47" during={1200}>
                <button>Learn more</button>
              </Ripples>
            </div>
          </div>

          <div className="col-md-6">
            <div className="supportCard wow fadeInLeft"  data-wow-duration=".70s">
              <div
                className="d-flex align-items-center"
                style={{ marginBottom: "24px" }}
              >
                <img src={account_manage} />
                <h4>General Enquiries</h4>
              </div>
              <p>
                If you're ever unsure, you can watch video tutorials to easily
                understand and get the help
              </p>
              <Ripples color="#ffffff47" during={1200}>
                <Link to={"/contact"}>
                  <button>Learn more</button>
                </Link>
              </Ripples>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default HelpSupport;
