import React from "react";
// import Carousel from "react-grid-carousel";
import SlideItem from "./SlideItem";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Pagination, Navigation } from "swiper/modules";

function Client(props) {
  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 2,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 2,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 1,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };
  return (
    <section className={props.class}>
      <div className="container ">
        <div className="row">
          <div className="col-md-12">
            <h4>
            Hear it Directly from Our <span>Global Users</span>
            </h4>
          </div>

          <div className="col-md-12">
            <Swiper
              slidesPerView={2}
              spaceBetween={30}
              // loop={true}
              autoplay={{
                delay: 4000,
                disableOnInteraction: false,
                pauseOnMouseEnter: true,
              }}
              pagination={{
                clickable: true,
              }}
              modules={[Autoplay, Pagination, Navigation]}
              className="mySwiper"
            >
              <SwiperSlide>
                <SlideItem />
              </SwiperSlide>
              <SwiperSlide>
                <SlideItem />
              </SwiperSlide>
              <SwiperSlide>
                <SlideItem />
              </SwiperSlide>
              <SwiperSlide>
                <SlideItem />
              </SwiperSlide>
            </Swiper>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Client;
