import { createSlice } from "@reduxjs/toolkit";
import { postAPICall, getAPICall } from "../client/Methodcall";
import { config } from "../client/config";

export const PlanesSlice = createSlice({
  name: "planes",
  initialState: {
    getPlanesResponse: {
      isLoading: false,
      response: {},
    },
  },
  reducers: {
    getPlanes: (state, action) => {
      state.getPlanesResponse = action.payload;
    },
    reset: (state, action) => {
      state.getPlanesResponse = {
        isLoading: false,
        response: {},
      };
    },
  },
});
export const resetAsync = () => async (dispatch) => {
  dispatch(reset());
};
export const getPlanesAsync = (body) => async (dispatch) => {
  try {
    dispatch(getPlanes({ isLoading: true }));
    var URL = `${process.env.REACT_APP_BASE_URL}${config.GET_PLANES}`;
    const result = await getAPICall(URL, body);
    dispatch(getPlanes({ isLoading: false, response: result.data }));
  } catch (error) {
    dispatch(getPlanes({ isLoading: false }));
  }
};

export const { getPlanes, reset } = PlanesSlice.actions;

export const getPlanesResponse = (state) => state.planes.getPlanesResponse;

export default PlanesSlice.reducer;
