import logo from "./logo.svg";
import React, { useEffect } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.min.js";
import "./App.css";
import Header from "./components/header/Header";
import HeroSection from "./components/hero/HeroSection";
import Partner from "./components/partners/Partner";
import BestServices from "./components/bestservices/BestServices";
import ClaritySolutions from "./components/claritysolutions/ClaritySolutions";
import ActiveUser from "./components/activeuser/ActiveUser";
import StartHassle from "./components/starthassle/StartHassle";
import Client from "./components/clients/Client";
import DownloadApp from "./components/downloadapp/DownloadApp";
import Footer from "./components/footer/Footer";
import PricingPurchase from "./components/pricing/PricingPurchase";
import Home from "./components/homepage/Home";
import { BrowserRouter, Route, Routes, useLocation } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import HelpSupport from "./containers/HelpSupport/HelpSupport";
import AboutUs from "./containers/About-us/AboutUs";
import ContactUs from "./containers/Contact/ContactUs";
import Thankyou from "./containers/Contact/Thankyou";
import ThankyouSale from "./containers/ContactSale/ThankyouSale";
import TermCondition from "./containers/TermCondition/TermCondition";
import PrivacyPolicy from "./containers/PrivacyPolicy/PrivacyPolicy";
import AccountManagement from "./containers/AccountManagement/AccountManagement";
import ContactSale from "./containers/ContactSale/ContactSale";
import VideoTutorials from "./containers/VideoTutorials/VideoTutorials";
import Sitemap from "./containers/Sitemap/Sitemap";
import Careers from "./containers/Careers/Careers";
import CareersDetail from "./containers/Careers/CareersDetail";
import AccountManagementDetail from "./containers/AccountManagementDetail/AccountManagementDetail";
import Faq from "./containers/Faq/Faq";

// import { useLocation } from "react-router-dom";

const ScrollToTop = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
};

function App() {
  // const location = useLocation();
  // useEffect(() => {
  //   // this changes the scrolling behavior to "smooth"
  //   window.scrollTo({ top: 0, behavior: "smooth" });
  // }, [location?.pathname]);
  return (
    <>
      <BrowserRouter onUpdate={() => window.scrollTo(0, 0)}>
        <ToastContainer />
        <Header />
        <ScrollToTop />
        <Routes>
          <Route index element={<Home />} />
          <Route exact path="/" element={<Home />} />
          <Route path="/home" element={<Home />} />
          <Route
            path="/pricing"
            element={<PricingPurchase class="pricing_purchase" />}
          />
          <Route path="/help-support" element={<HelpSupport />} />
          <Route path="/about" element={<AboutUs />} />
          <Route path="/contact" element={<ContactUs />} />
          <Route path="/thankyou-sale" element={<ThankyouSale />} />
          <Route path="/thankyou" element={<Thankyou />} />
          <Route path="/term-condition" element={<TermCondition />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route path="/account-management" element={<AccountManagement />} />
          <Route path="/contact-sale" element={<ContactSale />} />
          <Route path="/tutorials" element={<VideoTutorials />} />
          <Route path="/sitemap" element={<Sitemap />} />
          <Route path="/careers" element={<Careers />} />
          <Route path="/careers-detail" element={<CareersDetail />} />
          <Route path="/account-management-detail" element={<AccountManagementDetail />} />
          <Route path="/faq" element={<Faq />} />
        </Routes>
        <Footer class="footer" />
      </BrowserRouter>

      {/* <HeroSection class="herosection" />
      <Partner class="partnerSection" />
      <BestServices class="bestServices" />
      <ClaritySolutions class="claritySolution" />
      <ActiveUser class="activeUser" />
      <StartHassle class="startHassle" />
      <Client class="clientSection" />
      <DownloadApp class="downloadapp" />
      <Footer class="footer" /> */}

      {/* <PricingPurchase class="pricing_purchase" /> */}
    </>
  );
}

export default App;
