import { React, useState, useEffect } from "react";
import starthassle from "../../assets/img/starthassle.svg";
import { Button } from "bootstrap-4-react/lib/components";
import { Form, Modal } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import {
  resetAsync,
  setInvitationAsync,
  setInvitationResponse,
} from "../../service/slices/homeSlice";
import { toast } from "react-toastify";
import RegisterModal from "../modals/registerModal";
import Ripples from "react-ripples";
import { Link } from "react-router-dom";

function StartHassle(props) {
  const [show, setShow] = useState(false);
  const handleClose = () => {
    setShow(false);
  };
  const handleShow = () => setShow(true);

  return (
    <>
      <section className={props.class}>
        <div className="container">
          <div className="row">
            <div className="col-md-12 col-lg-6 col-xl-6 mb-5 mb-lg-0">
              <img className="wow fadeInUp"  data-wow-duration="1s" data-wow-delay=".20s" src={starthassle} />
            </div>
            <div className="col-md-12 col-lg-6 col-xl-6 d-flex align-items-center">
              <div className="caption wow fadeIn" data-wow-duration="1.5s" data-wow-delay=".30s">
                <h4>
                Protect Your <span>Customers</span> from Counterfeits
                </h4>
                <p>
                Clarity Codes allows customers verify product authenticity, report concerns directly to you with photos and details, and receive your latest product updates and messages via push notifications and videos.
                </p>
                <Ripples color="#ffffff47" during={1200}>
                  <Link to={process.env.REACT_APP_REGISTRATION_URL} target="_blank">
                    <Button
                      variant=""
                      className="subscribeBtn2"
                      // onClick={handleShow}
                    >
                      Get Started
                    </Button>
                  </Link>
                </Ripples>
              </div>
            </div>
          </div>
        </div>
      </section>

      <RegisterModal show={show} setShow={setShow} />
    </>
  );
}

export default StartHassle;
