import { createSlice } from "@reduxjs/toolkit";
import { getAPICall } from "../client/Methodcall";
import { config } from "../client/config";

export const VideoTutorialsSlice = createSlice({
  name: "video_tutorials",
  initialState: {
    videoTutorialsResponse: {
      isLoading: false,
      response: {},
    },
  },
  reducers: {
    getVideoTutorials: (state, action) => {
      state.videoTutorialsResponse = action.payload;
    },
    reset: (state, action) => {
      state.getVideoTutorialsResponse = {
        isLoading: false,
        response: {},
      };
    },
  },
});

export const resetAsync = () => async (dispatch) => {
  dispatch(reset());
};
export const getVideoTutorialsAsync = (body) => async (dispatch) => {
  try {
    dispatch(getVideoTutorials({ isLoading: true }));
    var URL = `${process.env.REACT_APP_BASE_URL}${config.GET_VIDEO_TUTORIALS}`;
    const result = await getAPICall(URL, body);
    dispatch(getVideoTutorials({ isLoading: false, response: result.data }));
  } catch (error) {
    dispatch(getVideoTutorials({ isLoading: false }));
  }
};

export const { getVideoTutorials, reset } = VideoTutorialsSlice.actions;

export const videoTutorialsResponse = (state) =>
  state.video_tutorials.videoTutorialsResponse;

export default VideoTutorialsSlice.reducer;
