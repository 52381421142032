import React from "react";
import address from "../../assets/img/address.svg";
import email from "../../assets/img/email.svg";
import phone from "../../assets/img/phone_contact.svg";
import Arrow_Swirl from "../../assets/img/Arrow_Swirl.svg";
import { Button, Form } from "react-bootstrap";
import FormControl from "../../components/contact/FormControl";
import { Link } from "react-router-dom";

function ThankyouSale(props) {
  return (
    <section className="contact_wrapper thankyou_page">
      <div className="container">
        <div className="row">
          <div className="col-md-12 col-lg-8 offset-lg-2">
            <h2 className="heading">
              Thank you for your <span>questions</span>
            </h2>
            <p className="head_para">
              Looking for some information or want to try one of our paid
              Clarity Client Plans? Submit your information and a Clarity
              representative will revert you back as soon as possible
            </p>
            <Link to={"/contact-sale"}>
              <button className="thanksbtn">Go to contact sales</button>
            </Link>
          </div>
        </div>

        <div className="row down_sec_contact">
          <div className="col-md-12">
            <h4>
              wanna seek more <span>help?</span>
            </h4>
          </div>

          <div className="col-md-6 mb-4 mb-md-0">
            <div className="down_mini_sec">
              <h5>
                Questions, Feedback, or Just Want to Say Hello? Reach Out to Us!
              </h5>
              <button>
                FAQ
                <img src={Arrow_Swirl} />
              </button>
            </div>
          </div>

          <div className="col-md-6">
            <div className="down_mini_sec two">
              <h5>
                If you're ever unsure, you can watch video tutorials to easily
                understand{" "}
              </h5>
              <Link to={"/tutorials"}>
                <button>
                  Watch Tutorials
                  <img src={Arrow_Swirl} />
                </button>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default ThankyouSale;
