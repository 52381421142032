import { configureStore } from "@reduxjs/toolkit";
import HomeSlice from "../slices/homeSlice";
import FaqsSlice from "../slices/faqsSlice";
import PlanesSlice from "../slices/planesSlice";
import aboutUsSlice from "../slices/aboutUsSlice";
import ContactSlice from "../slices/contactSlice";
import TeamsConditionSlice from "../slices/teamsConditionSlice";
import VideoTutorialsSlice from "../slices/videoTutorialsSlice";
import CareersSlice from "../slices/careersSlice";
import AccountManagementSlice from "../slices/accountManagementSlice";

export const store = configureStore({
  reducer: {
    home: HomeSlice,
    faqs: FaqsSlice,
    video_tutorials: VideoTutorialsSlice,
    planes: PlanesSlice,
    aboutus: aboutUsSlice,
    contact: ContactSlice,
    teamscondition: TeamsConditionSlice,
    careers: CareersSlice,
    accountManagement: AccountManagementSlice,
  },
});
