import React, { useState } from "react";
import { Link } from "react-router-dom";
import goback from "../../assets/img/goback.svg";
import { Button, Card, CardHeader, Modal } from "react-bootstrap";
import play_btn from "../../assets/img/play_btn.svg";

function Sitemap(props) {
  return (
    <>
      <section className="AccountManagement_section sitemap_page">
        <div className="container">
          <div className="row">
            <div className="col-md-12 text-center">
              <h2 className="heading">Sitemap</h2>
            </div>
          </div>
          <div className="row" style={{ marginBottom: "63px" }}>
            <div className="col-sm-12 col-md-4 col-lg-4 col-xl-4" style={{ marginBottom: "20px" }}>
            <div className="custom_card wow fadeInUp" data-wow-duration=".60s">
                <h2>Main</h2>
                <ul>
                  <li>
                    <Link to={"/"}>Home</Link>
                  </li>
                  {/* <li>
                    <Link to={"#"}>Company</Link>
                  </li> */}
                  {/* <li>
                    <Link to={"#"}>Resources</Link>
                  </li> */}
                  <li>
                    <Link to={"/pricing"}>Pricing</Link>
                  </li>
                  <li>
                    <Link to={"/tutorials"}>Video Tutorials</Link>
                  </li>
                  <li>
                    <Link to={"https://clientstage.clarity.codes/register"}>Register</Link>
                  </li>
                </ul>
              </div>
            </div>

            <div className="col-sm-12 col-md-4 col-lg-4 col-xl-4" style={{ marginBottom: "20px" }}>
            <div className="custom_card wow fadeInUp" data-wow-duration=".60s" data-wow-delay=".20s">
                <h2>Privacy</h2>
                <ul>
                  <li>
                    <Link to={"/term-condition"}>Terms & Conditions</Link>
                  </li>
                  <li>
                    <Link to={"/privacy-policy"}>Privacy Policy</Link>
                  </li>
                  <li>
                    <Link to={"/help-support"}>Help & Support</Link>
                  </li>
                  <li>
                    <Link to={"/account-management"}>Account Management</Link>
                  </li>
                  {/* <li>
                    <Link to={"#"}>Refund Policy</Link>
                  </li> */}
                </ul>
              </div>
            </div>

            <div className="col-sm-12 col-md-4 col-lg-4 col-xl-4" style={{ marginBottom: "20px" }}>
              <div className="custom_card wow fadeInUp" data-wow-duration=".60s" data-wow-delay=".40s">
                <h2>Company</h2>
                <ul>
                  <li>
                    <Link to={"/about"}>About</Link>
                  </li>
                  <li>
                    <Link to={"/careers"}>Career</Link>
                  </li>
                  <li>
                    <Link to={"/contact"}>General Enquiries</Link>
                  </li>
                  <li>
                    <Link to={"/contact-sale"}>Contact Sales</Link>
                  </li>
                </ul>
              </div>
            </div>
            
          </div>
        </div>
      </section>
    </>
  );
}

export default Sitemap;
