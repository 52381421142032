import React, { useEffect, useState } from "react";
import partner1 from "./../../assets/img/partners/1.svg";
import partner2 from "./../../assets/img/partners/2.svg";
import partner3 from "./../../assets/img/partners/3.svg";
import partner4 from "./../../assets/img/partners/4.svg";
import { useDispatch, useSelector } from "react-redux";
import {
  getPartnersAsync,
  getPartnersResponse,
} from "../../service/slices/homeSlice";
import { ColorRing } from "react-loader-spinner";
import { Image, Shimmer } from "react-shimmer";
// import Carousel from "react-grid-carousel";
// import Slider from "infinite-react-carousel";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Pagination, Navigation } from "swiper/modules";

function Partner(props) {
  // const responsive = {
  //   superLargeDesktop: {
  //     // the naming can be any, depends on you.
  //     breakpoint: { max: 4000, min: 3000 },
  //     items: 4,
  //   },
  //   desktop: {
  //     breakpoint: { max: 3000, min: 1024 },
  //     items: 4,
  //   },
  //   tablet: {
  //     breakpoint: { max: 1024, min: 464 },
  //     items: 4,
  //   },
  //   mobile: {
  //     breakpoint: { max: 464, min: 0 },
  //     items: 2,
  //   },
  // };
  const { partnersData, isLoading } = props;
  //   const [isLoading, setIsLoading] = useState(false);
  //   //   const [partnersData, setPartnersData] = useState([]);
  //   const dispatch = useDispatch();
  //   const _getPartnersResponse = useSelector(getPartnersResponse);
  //   useEffect(() => {
  //     setIsLoading(true);
  //     dispatch(
  //       getPartnersAsync({
  //         limit: 50,
  //       })
  //     );
  //   }, []);

  //   useEffect(() => {
  //     if (_getPartnersResponse?.response?.status == 200) {
  //       console.log(_getPartnersResponse, ":::data");
  //       setIsLoading(_getPartnersResponse?.isLoading);
  //       setPartnersData(_getPartnersResponse?.response?.data);
  //     }
  //   }, [_getPartnersResponse]);

  return (
    <section className={props.class}>
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <div className="myCard">
              <div className="row">
                <div className="col-md-12 col-lg-4 col-xl-4 mb-4 mb-lg-0">
                  <h2>Partner with</h2>
                  <h2>Prestigious Brands</h2>
                </div>
                <div className="col-md-12 col-lg-8 col-xl-8">
                  {/* <Carousel
                    cols={3}
                    rows={1}
                    gap={32}
                    loop={true}
                    showDots={false}
                    hideArrow={true}
                    autoplay={4000}
                  > */}
                  {/* <Carousel
                    responsive={responsive}
                    // shouldResetAutoplay={true}
                    rewind={true}
                    rewindWithAnimation={true}
                    showDots={true}
                    autoPlay={true}
                    autoPlaySpeed={2500}
                    // infinite={true}
                    swipeable={true}
                    draggable={false}
                    arrows={false}
                  > */}
                  <Swiper
                    slidesPerView={4}
                    spaceBetween={30}
                    loop={true}
                    autoplay={{
                      delay: 2500,
                      disableOnInteraction: false,
                    }}
                    pagination={{
                      clickable: true,
                    }}
                    modules={[Autoplay, Pagination, Navigation]}
                    className="mySwiper"
                  >
                    {partnersData?.map((partner) => (
                      <SwiperSlide>
                        {partner?.logo?.includes("http") ? (
                          <img
                            style={{
                              maxHeight: "100px",
                              maxWidth: "200px",
                              objectFit: "cover",
                              marginRight: "10px",
                            }}
                            src={partner?.logo}
                            key={partner?._id}
                          />
                        ) : (
                          ""
                        )}
                      </SwiperSlide>
                    ))}
                  </Swiper>
                  {/* </Carousel> */}
                  {/* </Carousel> */}

                  {/* <div>Item 1</div>
                    <div>Item 2</div>
                    <div>Item 3</div> */}

                  {/* <div className="d-flex align-items-center justify-content-start container_partner">
                    {!isLoading ? (
                      partnersData?.map((partner) => {
                        return (
                          <>
                            {partner?.logo?.includes("http") ? (
                              <img
                                style={{
                                  maxHeight: "100px",
                                  maxWidth: "200px",
                                  objectFit: "cover",
                                  marginRight: "10px",
                                }}
                                src={partner?.logo}
                                key={partner?._id}
                              />
                            ) : (
                              ""
                            )}
                          </>
                        );
                      })
                    ) : (
                      <div
                        className=""
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          columnGap: "20px",
                        }}
                      >
                        {Array(5)
                          .fill()
                          .map((ele, index) => {
                            return (
                              <Shimmer
                                className="partners-shimmer"
                                key={index}
                                height={100}
                                width={180}
                              />
                            );
                          })}
                      </div>
                    )}
                  </div> */}

                  {/* <div className="tech-slideshow">
                    <div className="mover-1">
                    {!isLoading ? (
                        partnersData?.map((partner) => {
                          return (
                            <>
                              {partner?.logo?.includes("http") ? (
                                <img
                                  style={{
                                    maxHeight: "100px",
                                    maxWidth: "200px",
                                    objectFit: "cover",
                                    marginRight: "10px",
                                  }}
                                  src={partner?.logo}
                                  key={partner?._id}
                                />
                              ) : (
                                ""
                              )}
                            </>
                          );
                        })
                      ) : (
                        <div
                          className=""
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            columnGap: "20px",
                          }}
                        >
                          {Array(5)
                            .fill()
                            .map((ele, index) => {
                              return (
                                <Shimmer
                                  className="partners-shimmer"
                                  key={index}
                                  height={100}
                                  width={180}
                                />
                              );
                            })}
                        </div>
                      )}
                    </div>
                  </div> */}
                </div>
              </div>
            </div>
          </div>

          <div className="col-md-12">
            {/* <Slider dots>
              <div>
                <h3>1dfdfd</h3>
              </div>
              <div>
                <h3>dfdfdf2</h3>
              </div>
              <div>
                <h3>3dfdfdf</h3>
              </div>
              <div>
                <h3>4dfdfdf</h3>
              </div>
              <div>
                <h3>dfdfdf5</h3>
              </div>
            </Slider> */}
          </div>
        </div>
      </div>
    </section>
  );
}

export default Partner;
