import React from "react";
import Logoipsum from "../../assets/img/Logoipsum.svg";
import stars from "../../assets/img/stars.svg";
import clientPic from "../../assets/img/clientPic.svg";
import quoteReview from "../../assets/img/quoteReview.svg";

function SlideItem(props) {
  return (
    <div className="slideItems">
      <img className="brandlogo" src={Logoipsum} />
      <h5>Amazing Tools</h5>
      <p>
        “Lorem ipsum dolor sit amet consectetur. Ac velit at placerat congue sit
        facilisis varius tellus. Lorem ipsum dolor sit amet consectetur. Ac
        velit at placerat congue sit facilisis varius tellus.”
      </p>
      <img className="rating" src={stars} />
      <div className="clientinfo d-flex justify-content-center align-items-center">
        <div>
          <img className="clientPic" src={clientPic} />
        </div>
        <div className="d-flex flex-column align-items-start">
          <h3>Mark</h3>
          <p>UI Designer</p>
        </div>
      </div>
      <img className="quoteReview" src={quoteReview} />
    </div>
  );
}

export default SlideItem;
