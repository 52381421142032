import { createSlice } from "@reduxjs/toolkit";
import { getAPICall, postFileAPICall } from "../client/Methodcall";
import { config } from "../client/config";

export const CareersSlice = createSlice({
  name: "careers",
  initialState: {
    getJobs: {
      isLoading: false,
      response: {},
    },
    getJob: {
      isLoading: false,
      response: {},
    },
    applyJob: {
      isLoading: false,
      response: {},
    },
  },
  reducers: {
    getJobs: (state, action) => {
      state.getJobs = action.payload;
    },
    getJob: (state, action) => {
      state.getJob = action.payload;
    },
    applyJob: (state, action) => {
      state.applyJob = action.payload;
    },
    reset: (state, action) => {
      state.getJobs = {
        isLoading: false,
        response: {},
      };
      state.getJob = {
        isLoading: false,
        response: {},
      };
      state.applyJob = {
        isLoading: false,
        response: {},
      };
    },
  },
});

export const resetAsync = () => async (dispatch) => {
  dispatch(reset());
};
export const getJobsAsync = (body) => async (dispatch) => {
  try {
    dispatch(getJobs({ isLoading: true }));
    var URL = `${process.env.REACT_APP_BASE_URL}${config.GET_JOBS}`;
    const result = await getAPICall(URL, body);
    dispatch(getJobs({ isLoading: false, response: result.data }));
  } catch (error) {
    dispatch(getJobs({ isLoading: false }));
  }
};
export const getJobAsync = (body) => async (dispatch) => {
  try {
    dispatch(getJob({ isLoading: true }));
    var URL = `${process.env.REACT_APP_BASE_URL}${config.GET_JOB}`;
    const result = await getAPICall(URL, body);
    dispatch(getJob({ isLoading: false, response: result.data }));
  } catch (error) {
    dispatch(getJob({ isLoading: false }));
  }
};
export const applyJobAsync = (body) => async (dispatch) => {
  try {
    dispatch(applyJob({ isLoading: true }));
    var URL = `${process.env.REACT_APP_BASE_URL}${config.APPLY_JOB}`;
    const result = await postFileAPICall(URL, body);
    dispatch(applyJob({ isLoading: false, response: result.data }));
  } catch (error) {
    dispatch(applyJob({ isLoading: false }));
  }
};

export const { getJobs, getJob, applyJob, reset } = CareersSlice.actions;

export const getJobsResponse = (state) => state.careers.getJobs;
export const getJobResponse = (state) => state.careers.getJob;
export const applyJobResponse = (state) => state.careers.applyJob;

export default CareersSlice.reducer;
