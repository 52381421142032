import React from "react";
import realtime from "../../assets/img/realtime.svg";
import counterfietReport from "../../assets/img/counterfietReport.svg";
import reportIcon from "../../assets/img/reportIcon.svg";
import businessTrack from "../../assets/img/businessTrack.svg";
import employeeIcon from "../../assets/img/employeeIcon.svg";
import seamlessIcon from "../../assets/img/seamlessIcon.svg";
import Card from "./Card";

function ClaritySolutions(props) {
  return (
    <section className={props.class}>
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <h4>
            Why Invest In The <span>Clarity</span> Codes Solution?
            </h4>
            <p>
            Take a look at the top features that Clarity Codes provides for you.
            </p>
          </div>

          <div className="col-md-6 col-lg-4 col-xl-4 wow fadeInUp">
            <Card
              icon={counterfietReport}
              title="Counterfeit Reporting"
              content="Reporting counterfeit products protects consumers, supports legitimate businesses, preserves brand integrity, and reduces the risk of harmful goods in the market."
            />
          </div>

          <div className="col-md-6 col-lg-4 col-xl-4 wow fadeInLeft">
            <Card
              icon={realtime}   
              title="Real-time Location"
              content="Easily identify hotspots where counterfeit items are being distributed with real-time data reporting when customers scan the codes on your products."
            />
          </div>

          <div className="col-md-6 col-lg-4 col-xl-4 wow fadeInRight">
            <Card
              icon={seamlessIcon}
              title="Manage Codes Seamlessly"
              content="Generating batches of codes enhances efficiency and accuracy in export or print processes, minimizing errors, saving time, and ensuring consistent quality."
            />
          </div>
          
          <div className="col-md-6 col-lg-4 col-xl-4 wow fadeInRight">
            <Card
              icon={reportIcon}
              title="Create Unlimited Products"
              content="Create unlimited products in seconds with detailed information, including images, manufacturing locations, product numbers and batch numbers, ensuring transparency for customers."
            />
          </div>

          <div className="col-md-6 col-lg-4 col-xl-4 wow fadeInUp">
            <Card
              icon={employeeIcon}
              title="Team Management"
              content="Add users to your team to manage code printing processes or handle counterfeit reporting, allowing for efficient teamwork and streamlined workflows."
            />
          </div>

          <div className="col-md-6 col-lg-4 col-xl-4 wow fadeInLeft">
            <Card
              icon={businessTrack}
              title="Custom Branded Portal"
              content="Personalize your app with custom branding and push notifications, adding a professional touch to the user experience and increasing brand recognition."
            />
          </div>
          
          
          
        </div>
      </div>
    </section>
  );
}

export default ClaritySolutions;
