import React, { useRef, useEffect } from "react";
import mapboxgl from "mapbox-gl";
import countryStateData from "../../common/countryState.json";
import "./home.css";
import $ from "jquery"; // Import jQuery if not already done

mapboxgl.accessToken = process.env.REACT_APP_MAPBOX_ACCESS_TOKEN;

function LocationChartComponent(props) {
  const { activeLocations, allActiveLocations, maximumActiveUser } = props;
  let mapContainer = useRef(null);
  let features = [
    {
      type: "Feature",
      geometry: {
        type: "Point",
        coordinates: [
          "-6.337400013393106",
          "52.24951333729699",
          "5 St Martin's Rd, Ballygillane Big, Co. Wexford, Ireland",
        ],
      },
      properties: {
        title: "Mapbox",
        activeUsers: 1,
        iconSize: [60, 60],
      },
    },
    {
      type: "Feature",
      geometry: {
        type: "Point",
        coordinates: [
          "-6.304864",
          "53.328430",
          "5 St Martin's Rd, Ballygillane Big, Co. Wexford, Ireland",
        ],
      },
      properties: {
        title: "Mapbox",
        activeUsers: 1,
        iconSize: [60, 60],
      },
    },
    {
      type: "Feature",
      geometry: {
        type: "Point",
        coordinates: [
          "-6.337391934876766",
          "52.24964754398324",
          "Saint Joseph's, St Martin's Rd, Ballygillane Big, Rosslare Harbour, Co. Wexford, Ireland",
        ],
      },
      properties: {
        title: "Mapbox",
        activeUsers: 1,
        iconSize: [60, 60],
      },
    },
    {
      type: "Feature",
      geometry: {
        type: "Point",
        coordinates: [
          "-6.3373023",
          "52.2497053",
          "Saint Joseph's, St Martin's Rd, Ballygillane Big, Rosslare Harbour, Co. Wexford, Ireland",
        ],
      },
      properties: {
        title: "Mapbox",
        activeUsers: 1,
        iconSize: [60, 60],
      },
    },
    {
      type: "Feature",
      geometry: {
        type: "Point",
        coordinates: [
          "-6.3373021",
          "52.2497012",
          "Saint Joseph's, St Martin's Rd, Ballygillane Big, Rosslare Harbour, Co. Wexford, Ireland",
        ],
      },
      properties: {
        title: "Mapbox",
        activeUsers: 1,
        iconSize: [60, 60],
      },
    },
    {
      type: "Feature",
      geometry: {
        type: "Point",
        coordinates: [
          "-6.337379391745271",
          "52.2497167559578",
          "Slievenamon, St Martin's Rd, Ballygillane Big, Rosslare Harbour, Co. Wexford, Ireland",
        ],
      },
      properties: {
        title: "Mapbox",
        activeUsers: 1,
        iconSize: [60, 60],
      },
    },
    {
      type: "Feature",
      geometry: {
        type: "Point",
        coordinates: [
          "-122.406417",
          "37.785834",
          "1800 Ellis St, San Francisco, CA 94115, USA",
        ],
      },
      properties: {
        title: "Mapbox",
        activeUsers: 1,
        iconSize: [60, 60],
      },
    },
    {
      type: "Feature",
      geometry: {
        type: "Point",
        coordinates: [
          "81.7286948",
          "16.5314664",
          "Joshi Cotton, Ullampar Main Rd, Ullamparu, Palakollu, Andhra Pradesh 534267, India",
        ],
      },
      properties: {
        title: "Mapbox",
        activeUsers: 1,
        iconSize: [60, 60],
      },
    },
    {
      type: "Feature",
      geometry: {
        type: "Point",
        coordinates: [
          "77.6218943",
          "13.0156249",
          "881, Vinobha Nagar, Kadugondanahalli, Bengaluru, Karnataka 560045, India",
        ],
      },
      properties: {
        title: "Mapbox",
        activeUsers: 1,
        iconSize: [60, 60],
      },
    },
    {
      type: "Feature",
      geometry: {
        type: "Point",
        coordinates: [
          "-6.337424108033683",
          "52.2495060294214",
          "5 St Martin's Rd, Ballygillane Big, Co. Wexford, Ireland",
        ],
      },
      properties: {
        title: "Mapbox",
        activeUsers: 1,
        iconSize: [60, 60],
      },
    },
    {
      type: "Feature",
      geometry: {
        type: "Point",
        coordinates: [
          "-82.14984726375509",
          "28.212592872855787",
          "3767 Correia dr, Zephyrhills, FL 33542, USA",
        ],
      },
      properties: {
        title: "Mapbox",
        activeUsers: 1,
        iconSize: [60, 60],
      },
    },
    {
      type: "Feature",
      geometry: {
        type: "Point",
        coordinates: [
          "75.2299455",
          "30.0732102",
          "36FJ+64P, Dashmesh Nagar, Maur, Punjab 151509, India",
        ],
      },
      properties: {
        title: "Mapbox",
        activeUsers: 1,
        iconSize: [60, 60],
      },
    },
    {
      type: "Feature",
      geometry: {
        type: "Point",
        coordinates: [
          "-6.3374124",
          "52.2494963",
          "5 St Martin's Rd, Ballygillane Big, Co. Wexford, Ireland",
        ],
      },
      properties: {
        title: "Mapbox",
        activeUsers: 1,
        iconSize: [60, 60],
      },
    },
    {
      type: "Feature",
      geometry: {
        type: "Point",
        coordinates: [
          "-6.337349042323786",
          "52.24977428162958",
          "Saint Joseph's, St Martin's Rd, Ballygillane Big, Rosslare Harbour, Co. Wexford, Ireland",
        ],
      },
      properties: {
        title: "Mapbox",
        activeUsers: 1,
        iconSize: [60, 60],
      },
    },
    {
      type: "Feature",
      geometry: {
        type: "Point",
        coordinates: [
          "-6.4596428",
          "52.3335637",
          "1 King St Ave, Whitewell, Wexford, Y35 H2X3, Ireland",
        ],
      },
      properties: {
        title: "Mapbox",
        activeUsers: 1,
        iconSize: [60, 60],
      },
    },
    {
      type: "Feature",
      geometry: {
        type: "Point",
        coordinates: [
          "70.4422373",
          "21.544211",
          "Junagadh Bypass Road, Hariom Nagar, Vishnu Colony, Zanzarda, Gujarat, India",
        ],
      },
      properties: {
        title: "Mapbox",
        activeUsers: 1,
        iconSize: [60, 60],
      },
    },
    {
      type: "Feature",
      geometry: {
        type: "Point",
        coordinates: [
          "72.53895042950678",
          "23.060537111931012",
          "66, Vardhmannagar Society, C.P. Nagar-1, Parul Nagar Society, Ahmedabad, Gujarat 380061, India",
        ],
      },
      properties: {
        title: "Mapbox",
        activeUsers: 1,
        iconSize: [60, 60],
      },
    },
    {
      type: "Feature",
      geometry: {
        type: "Point",
        coordinates: [
          "77.3714486",
          "28.6100663",
          "J96C+2HG, Sector 62 Rd, C Block, Phase 2, Industrial Area, Sector 62, Noida, Uttar Pradesh 201301, India",
        ],
      },
      properties: {
        title: "Mapbox",
        activeUsers: 1,
        iconSize: [60, 60],
      },
    },
    {
      type: "Feature",
      geometry: {
        type: "Point",
        coordinates: [
          "72.95982530482118",
          "20.589277812395316",
          "HXQ5+QW3, Valsad - Dharampur Rd, Atak Pardi, Valsad, Gujarat 396055, India",
        ],
      },
      properties: {
        title: "Mapbox",
        activeUsers: 1,
        iconSize: [60, 60],
      },
    },
    {
      type: "Feature",
      geometry: {
        type: "Point",
        coordinates: [
          "77.37874664006038",
          "28.619007652981967",
          "A-130, 91SpringBoard, A Block, Sector 63, Noida, Uttar Pradesh 201301, India",
        ],
      },
      properties: {
        title: "Mapbox",
        activeUsers: 1,
        iconSize: [60, 60],
      },
    },
    {
      type: "Feature",
      geometry: {
        type: "Point",
        coordinates: [
          "77.37874664006038",
          "28.619007652981967",
          "A-130, 91SpringBoard, A Block, Sector 63, Noida, Uttar Pradesh 201301, India",
        ],
      },
      properties: {
        title: "Mapbox",
        activeUsers: 1,
        iconSize: [60, 60],
      },
    },
    {
      type: "Feature",
      geometry: {
        type: "Point",
        coordinates: [
          "72.9340387",
          "20.5906924",
          "3R/R, Sonanagar, Valsad, Gujarat 396001, India",
        ],
      },
      properties: {
        title: "Mapbox",
        activeUsers: 1,
        iconSize: [60, 60],
      },
    },
    {
      type: "Feature",
      geometry: {
        type: "Point",
        coordinates: [
          "76.77621769999999",
          "30.7377812",
          "Sector 17 Bus Stand Road, 17H, 17F, Sector 17, Chandigarh, India",
        ],
      },
      properties: {
        title: "Mapbox",
        activeUsers: 1,
        iconSize: [60, 60],
      },
    },
    {
      type: "Feature",
      geometry: {
        type: "Point",
        coordinates: ["-74.005974", "40.712776", "elba 13 avenue"],
      },
      properties: {
        title: "Mapbox",
        activeUsers: 1,
        iconSize: [60, 60],
      },
    },
    {
      type: "Feature",
      geometry: {
        type: "Point",
        coordinates: [
          "77.37874664006038",
          "28.619007652981967",
          "A-130, 91SpringBoard, A Block, Sector 63, Noida, Uttar Pradesh 201301, India",
        ],
      },
      properties: {
        title: "Mapbox",
        activeUsers: 1,
        iconSize: [60, 60],
      },
    },
    {
      type: "Feature",
      geometry: {
        type: "Point",
        coordinates: [
          "72.51864246724655",
          "23.051597955898835",
          "407-412, President Plaza, opposite TITANIUM SQUARE, Jay Ambe Nagar, Patel Society, Jai Ambe Nagar, Thaltej, Ahmedabad, Gujarat 380054, India",
        ],
      },
      properties: {
        title: "Mapbox",
        activeUsers: 1,
        iconSize: [60, 60],
      },
    },
    {
      type: "Feature",
      geometry: {
        type: "Point",
        coordinates: ["-74.005974", "40.712776", "sector-2 noida"],
      },
      properties: {
        title: "Mapbox",
        activeUsers: 1,
        iconSize: [60, 60],
      },
    },
    {
      type: "Feature",
      geometry: {
        type: "Point",
        coordinates: [
          "77.3786912",
          "28.61896",
          "A-131, A Block, Sector 63, Noida, Uttar Pradesh 201301, India",
        ],
      },
      properties: {
        title: "Mapbox",
        activeUsers: 1,
        iconSize: [60, 60],
      },
    },
    {
      type: "Feature",
      geometry: {
        type: "Point",
        coordinates: ["-74.005974", "40.712776", ""],
      },
      properties: {
        title: "Mapbox",
        activeUsers: 1,
        iconSize: [60, 60],
      },
    },
    {
      type: "Feature",
      geometry: {
        type: "Point",
        coordinates: [
          "72.95977770588762",
          "20.58885050484853",
          "HXQ5+GRX, Valsad - Dharampur Rd, Atak Pardi, Valsad, Gujarat 396055, India",
        ],
      },
      properties: {
        title: "Mapbox",
        activeUsers: 1,
        iconSize: [60, 60],
      },
    },
    {
      type: "Feature",
      geometry: {
        type: "Point",
        coordinates: [
          "76.77292229999999",
          "30.7315418",
          "Sector 22 Market Road, 22C, Sector 22, Chandigarh, India",
        ],
      },
      properties: {
        title: "Mapbox",
        activeUsers: 1,
        iconSize: [60, 60],
      },
    },
    {
      type: "Feature",
      geometry: {
        type: "Point",
        coordinates: [
          "77.37867917391557",
          "28.618966370240233",
          "A-131, A Block, Sector 63, Noida, Uttar Pradesh 201301, India",
        ],
      },
      properties: {
        title: "Mapbox",
        activeUsers: 1,
        iconSize: [60, 60],
      },
    },
    {
      type: "Feature",
      geometry: {
        type: "Point",
        coordinates: [
          "72.838987",
          "18.9510043",
          "Office. No: 15 , C / O Jayantilal Chandulal Godown, Tukdoji Maharaj St, Masjid Bandar East, Masjid Bandar, Mumbai, Maharashtra 400009, India",
        ],
      },
      properties: {
        title: "Mapbox",
        activeUsers: 1,
        iconSize: [60, 60],
      },
    },
    {
      type: "Feature",
      geometry: {
        type: "Point",
        coordinates: ["80.9612781", "26.93129", "R.k Complex"],
      },
      properties: {
        title: "Mapbox",
        activeUsers: 1,
        iconSize: [60, 60],
      },
    },
    {
      type: "Feature",
      geometry: {
        type: "Point",
        coordinates: [
          "-122.084",
          "37.4219983",
          "1600 Amphitheatre Pkwy, Mountain View, CA 94043, USA",
        ],
      },
      properties: {
        title: "Mapbox",
        activeUsers: 1,
        iconSize: [60, 60],
      },
    },
    {
      type: "Feature",
      geometry: {
        type: "Point",
        coordinates: [
          "-122.084",
          "37.4219983",
          "1600 Amphitheatre Pkwy, Mountain View, CA 94043, USA",
        ],
      },
      properties: {
        title: "Mapbox",
        activeUsers: 1,
        iconSize: [60, 60],
      },
    },
    {
      type: "Feature",
      geometry: {
        type: "Point",
        coordinates: [
          "72.3749471",
          "23.8042066",
          "R93F+MXP, Ahmedabad - Patan Highway Rd, Bharat Nagar, Unjha, Gujarat 384170, India",
        ],
      },
      properties: {
        title: "Mapbox",
        activeUsers: 1,
        iconSize: [60, 60],
      },
    },
    {
      type: "Feature",
      geometry: {
        type: "Point",
        coordinates: [
          "72.5188189",
          "23.0502412",
          "Vasant Nature Cure Hospital Thaltej Tekra Ahmedabad -380054, near Udgam School, Patel Society, Jai Ambe Nagar, Thaltej, Ahmedabad, Gujarat 380054, India",
        ],
      },
      properties: {
        title: "Mapbox",
        activeUsers: 1,
        iconSize: [60, 60],
      },
    },
    {
      type: "Feature",
      geometry: {
        type: "Point",
        coordinates: [
          "77.37872",
          "28.6189483",
          "A-130, A Block, Sector 63, Noida, Uttar Pradesh 201301, India",
        ],
      },
      properties: {
        title: "Mapbox",
        activeUsers: 1,
        iconSize: [60, 60],
      },
    },
    {
      type: "Feature",
      geometry: {
        type: "Point",
        coordinates: [
          "72.6298936",
          "22.9874639",
          "1/2, Hatkeshwar, Ahmedabad, Gujarat 380038, India",
        ],
      },
      properties: {
        title: "Mapbox",
        activeUsers: 1,
        iconSize: [60, 60],
      },
    },
    {
      type: "Feature",
      geometry: {
        type: "Point",
        coordinates: [
          "75.8818833",
          "30.8740007",
          "8210/5, Gurpal Nagar, Ludhiana, Punjab 141003, India",
        ],
      },
      properties: {
        title: "Mapbox",
        activeUsers: 1,
        iconSize: [60, 60],
      },
    },
    {
      type: "Feature",
      geometry: {
        type: "Point",
        coordinates: [
          "80.9612062",
          "26.931405",
          "647B/l-188A, Sector J, Sector D, Jankipuram, Lucknow, Uttar Pradesh 226021, India",
        ],
      },
      properties: {
        title: "Mapbox",
        activeUsers: 1,
        iconSize: [60, 60],
      },
    },
    {
      type: "Feature",
      geometry: {
        type: "Point",
        coordinates: [
          "-122.083922",
          "37.4220936",
          "P1600 Amphitheatre Pkwy, Mountain View, CA 94043, USA",
        ],
      },
      properties: {
        title: "Mapbox",
        activeUsers: 1,
        iconSize: [60, 60],
      },
    },
    {
      type: "Feature",
      geometry: {
        type: "Point",
        coordinates: ["-74.005974", "40.712776", null],
      },
      properties: {
        title: "Mapbox",
        activeUsers: 1,
        iconSize: [60, 60],
      },
    },
  ];
  // allActiveLocations?.map((ele) => ({
  //   type: "Feature",
  //   geometry: {
  //     type: "Point",
  //     coordinates: ele.coordinates,
  //   },
  //   properties: {
  //     title: "Mapbox",
  //     activeUsers: 1,
  //     // ele.count,
  //     iconSize: [60, 60],
  //   },
  // }));
  let geojson = {
    type: "FeatureCollection",
    features,
  };

  useEffect(() => {
    // const map = new mapboxgl.Map({
    //   container: mapContainer,
    //   style: 'mapbox://styles/mapbox/streets-v11',
    //   zoom: 1,
    // });
    // let legend = document.getElementById('legend');
    // var layers = [
    //   '0-10',
    //   '10-20',
    //   '20-50',
    //   '50-100',
    //   '100-200',
    //   '200-500',
    //   '500-1000',
    //   '1000+',
    // ];
    // var colors = [
    //   '#FFEDA0',
    //   '#FED976',
    //   '#FEB24C',
    //   '#FD8D3C',
    //   '#FC4E2A',
    //   '#E31A1C',
    //   '#BD0026',
    //   '#800026',
    // ];
    // for (let i = 0; i < layers.length; i++) {
    //   var layer = layers[i];
    //   var color = colors[i];
    //   var item = document.createElement('div');
    //   var key = document.createElement('span');
    //   key.className = 'legend-key';
    //   key.style.backgroundColor = color;

    //   var value = document.createElement('span');
    //   value.innerHTML = layer;
    //   item.appendChild(key);
    //   item.appendChild(value);
    //   legend.appendChild(item);
    // }

    // new mapboxgl.featureLayer().setGeoJSON(geojson).addTo(map);
    // map.on('load', function() {
    //   map.resize();
    //   map.loadImage(
    //     'https://docs.mapbox.com/mapbox-gl-js/assets/custom_marker.png',
    //     // Add an image to use as a custom marker
    //     function(error, image) {
    //       if (error) throw error;
    //       map.addImage('custom-marker', image);

    //       map.addSource('places', {
    //         type: 'geojson',
    //         data: geojson,
    //       });

    //       // Add a layer showing the places.
    //       map.addLayer({
    //         id: 'places',
    //         type: 'symbol',
    //         source: 'places',
    //         layout: {
    //           'icon-image': 'custom-marker',
    //           'icon-allow-overlap': true,
    //         },
    //       });
    //     },
    //   );
    // });
    // if (allActiveLocations && allActiveLocations.length) {
    // mapContainer.current = null;
    var map = new mapboxgl.Map({
      container: mapContainer,
      minZoom: 0.0,
      maxZoom: 0.0,
      zoom: 0.0,
      // center: [20, 20],
      style: "mapbox://styles/testchapter/ckh68w3570l5919mlivoz6x23",
      scrollZoom: false,
      boxZoom: false,
      doubleClickZoom: false,
      // 'mapbox://styles/testchapter/ckgxoje2n0w2319o5lr5ekz9q',
      // 'mapbox://styles/testchapter/ckgxh9n6k2r6o19lhoe8h7575',
      // 'mapbox://styles/mapbox/light-v10',
    });
    // map.addControl(new mapboxgl.NavigationControl());
    map.scrollZoom.disable();

    // add markers to map
    let max = 44;

    geojson?.features?.forEach(function (marker) {
      console.log(marker);
      let min = marker.properties.activeUsers;
      let percentage = (min / max) * 100;
      let heightWidth = Math.round((percentage / 100) * 30 + 10);

      // create a HTML element for each feature
      var el = document.createElement("div");
      el.className = "custom-marker";
      el.style.backgroundColor =
        marker.properties.activeUsers > 50 ? "#0f51a1" : "#f89c41";
      // 'url(https://placekitten.com/g/' +
      // marker.properties.iconSize.join('/') +
      // '/)';

      el.style.width = `${heightWidth}px`;
      // el.style.boxShadow =
      //   marker.properties.activeUsers > 50
      //     ? '0px 0px 9px 4px #0f51a1'
      //     : '0px 0px 9px 4px #f89c41';
      // `${Math.sqrt(marker.properties.activeUsers) + 20}px`;
      // marker.properties.iconSize[0] + 'px';
      el.style.height = `${heightWidth}px`;
      // `${Math.sqrt(marker.properties.activeUsers) + 20}px`;
      //  marker.properties.iconSize[1] + 'px';
      // make a marker for each feature and add to the map
      // console.log(marker.geometry.coordinates, "jghfkjrhffbsjgvks");
      new mapboxgl.Marker(el).setLngLat(marker.geometry.coordinates).addTo(map);
    });
    // eslint-disable-next-line

    $(document).ready(function () {
      var divsWithDuplicates = $(".mapboxgl-canvas-container")
        .toArray()
        .reduce(function (acc, currentDiv, index, array) {
          if (
            array.indexOf(currentDiv) !== index &&
            acc.indexOf(currentDiv) === -1
          ) {
            acc.push(currentDiv);
          }
          return acc;
        }, []);

      $(divsWithDuplicates).remove();
    });
  }, []);
  const DEFAULT_CDN_URL =
    "https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.4.3/flags/4x3/";
  const DEFAULT_CDN_SUFFIX = "svg";

  const getCountryFlag = (countryCode) => {
    var result = countryStateData.filter((x) => x.code3 === countryCode);
    return result[0]?.code2;
  };

  $(document).ready(function () {
    var divCounter = 0;
    $(".mapContainer > .mapboxgl-canvas-container").each(function (index) {
      console.log(this, index);
      // ++divCounter;
      console.log(divCounter);
      if (index == 0) {
        $(this).hide();
      }
    });
  });

  return (
    <div className={"row mb-2"}>
      <div className={"col-md-12"}>
        <div
          ref={(el) => (mapContainer = el)}
          className="mapContainer geo-chart"
        />
        {/* <div className='map-overlay' id={'legend'}></div> */}
      </div>
    </div>
  );
}

export default LocationChartComponent;
