export const config = {
  GET_PARTNERS: "/web/partners/get-all-partners",
  GET_FAQS: "/web/faqs/get-all-faq",
  GET_PLANES: "/web/subscription-plans/get-subscription-plans",
  SET_INVITATION: "/api/v1.1/clients/invite",
  GET_COUNT: "/web/about/get-Count",
  GET_ABOUT_US: "/web/about/get-about-us",
  SET_CONTACT: "/web/enquiries/create-general-enquiry",
  SET_SALES: "/web/enquiries/create-sales-enquiry",
  GET_TEAMS_CONDITION: "/web/settings/get-terms-and-conditions",
  GET_PRIVACY_POLICY: "/web/settings/get-privacy-policy",
  GET_VIDEO_TUTORIALS: "/web/tutorials/get-video-tutorials",
  GET_JOBS: "/web/careers/get-job-titles",
  GET_JOB: "/web/careers/get-job-details",
  APPLY_JOB: "/web/careers/apply-job",
  ACCOUNTMANAGEMENT: "/web/account-management/get-topics",
  CONTACTS: "/web/contact-us/get-contact-details",
  GET_USER_LOCATIONS: "/web/users/active-user-locations",
};
