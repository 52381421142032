import { createSlice } from "@reduxjs/toolkit";
import { postAPICall, getAPICall } from "../client/Methodcall";
import { config } from "../client/config";

export const HomeSlice = createSlice({
  name: "home",
  initialState: {
    getPartnersResponse: {
      isLoading: false,
      response: {},
    },
    setInvitationResponse: {
      isLoading: false,
      response: {},
    },
    getUserLocationsResponse: {
      isLoading: false,
      response: {}
    }
  },
  reducers: {
    getPartners: (state, action) => {
      state.getPartnersResponse = action.payload;
    },
    setInvitation: (state, action) => {
      state.setInvitationResponse = action.payload;
    },
    getUserLocations: (state, action) => {
       state.getUserLocationsResponse= action.payload
    },
    reset: (state, action) => {
      state.setInvitationResponse = {
        isLoading: false,
        response: {},
      };
    },
  },
});
export const resetAsync = () => async (dispatch) => {
  dispatch(reset());
};
export const getPartnersAsync = (body) => async (dispatch) => {
  try {
    dispatch(getPartners({ isLoading: true }));
    var URL = `${process.env.REACT_APP_BASE_URL}${config.GET_PARTNERS}`;
    const result = await getAPICall(URL, body);
    dispatch(getPartners({ isLoading: false, response: result.data }));
  } catch (error) {
    dispatch(getPartners({ isLoading: false }));
  }
};

export const setInvitationAsync = (body, callback) => async (dispatch) => {
  try {
    dispatch(setInvitation({ isLoading: true }));
    var URL = `https://apistage.clarity.codes/v1.1/clients/invite`;
    const result = await postAPICall(URL, body).then((res) => callback(res));
    dispatch(setInvitation({ isLoading: false, response: result.data }));
  } catch (error) {
    dispatch(setInvitation({ isLoading: false }));
  }
};

export const getUserLocationsAsync = (body) => async (dispatch) => {
  try {
    dispatch(getUserLocations({ isLoading: true }));
    var URL = `${process.env.REACT_APP_BASE_URL}${config.GET_USER_LOCATIONS}`;
    const result = await getAPICall(URL, body);
    dispatch(getUserLocations({ isLoading: false, response: result.data }));
  } catch (error) {
    dispatch(getUserLocations({ isLoading: false }));
  }
};

export const { getPartners, setInvitation,getUserLocations, reset } = HomeSlice.actions;

export const getPartnersResponse = (state) => state.home.getPartnersResponse;
export const setInvitationResponse = (state) =>
  state.home.setInvitationResponse;
export const getUserLocationsResponse = (state) => state.home.getUserLocationsResponse;

export default HomeSlice.reducer;
