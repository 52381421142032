import { createSlice } from "@reduxjs/toolkit";
import { getAPICall } from "../client/Methodcall";
import { config } from "../client/config";

export const AccountManagementSlice = createSlice({
  name: "accountManagement",
  initialState: {
    getAccountManagement: {
      isLoading: false,
      response: {},
    },
  },
  reducers: {
    getAccountManagement: (state, action) => {
      state.getAccountManagement = action.payload;
    },
    reset: (state, action) => {
      state.getAccountManagement = {
        isLoading: false,
        response: {},
      };
    },
  },
});

export const resetAsync = () => async (dispatch) => {
  dispatch(reset());
};
export const getAccountManagementAsync = (body) => async (dispatch) => {
  try {
    dispatch(getAccountManagement({ isLoading: true }));
    var URL = `${process.env.REACT_APP_BASE_URL}${config.ACCOUNTMANAGEMENT}`;
    const result = await getAPICall(URL, body);
    dispatch(getAccountManagement({ isLoading: false, response: result.data }));
  } catch (error) {
    dispatch(getAccountManagement({ isLoading: false }));
  }
};

export const { getAccountManagement, reset } = AccountManagementSlice.actions;

export const getAccountManagementResponse = (state) =>
  state.accountManagement.getAccountManagement;

export default AccountManagementSlice.reducer;
