import React from "react";
import appleStore from "../../assets/img/appleStore.svg";
import andoridApp from "../../assets/img/andoridApp.svg";
import loginHand from "../../assets/img/loginHand2.svg";

function DownloadApp(props) {
  const appleStoreUrl = process.env.REACT_APP_APPLE_STORE_URL;
  const appStoreUrl = process.env.REACT_APP_APP_STORE_URL;
  return (
    <section className={props.class}>
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <div className="customCard">
              <h4>Download mobile version</h4>
              <p>
              Download Clarity Codes on App Store or Google Play for on-the-go counterfeit protection – anytime, anywhere.
              </p>
              <div className="d-flex">
                <a href={appleStoreUrl} target="_blank">
                  <img src={appleStore} style={{ marginRight: "24px" }} />
                </a>
                <a href={appStoreUrl} target="_blank">
                  <img src={andoridApp} />
                </a>
              </div>
              <img className="loginHand" src={loginHand} />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default DownloadApp;
