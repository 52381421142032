import React from "react";

function SideTable(props) {
  return (
    <table className="sidebar_table">
      <tr>
        <th>Features</th>
      </tr>
      <tr>
        <td>Users</td>
      </tr>
      <tr>
        <td>Staff Permission</td>
      </tr>
      <tr>
        <td>Batch Export</td>
      </tr>
      {/* <tr>
        <td>Brand and Certificate</td>
      </tr> */}
      <tr>
        <td>Code Printing</td>
      </tr>
      <tr>
        <td>Domain Set Up</td>
      </tr>
    </table>
  );
}

export default SideTable;
