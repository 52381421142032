import { createSlice } from "@reduxjs/toolkit";
import { postAPICall, getAPICall } from "../client/Methodcall";
import { config } from "../client/config";

export const ContactSlice = createSlice({
  name: "contact",
  initialState: {
    setContactResponse: {
      isLoading: false,
      response: {},
    },
    setSaleResponse: {
      isLoading: false,
      response: {},
    },
    getContacts: {
      isLoading: false,
      response: {},
    },
  },
  reducers: {
    setContact: (state, action) => {
      state.setContactResponse = action.payload;
    },
    setSales: (state, action) => {
      state.setSaleResponse = action.payload;
    },
    getContacts: (state, action) => {
      state.getContacts = action.payload;
    },
    reset: (state, action) => {
      state.setContactResponse = {
        isLoading: false,
        response: {},
      };
    },
  },
});
export const resetAsync = () => async (dispatch) => {
  dispatch(reset());
};
export const setContactAsync = (body, callback) => async (dispatch) => {
  try {
    dispatch(setContact({ isLoading: true }));
    var URL = `${process.env.REACT_APP_BASE_URL}${config.SET_CONTACT}`;
    const result = await postAPICall(URL, body).then((res) => callback(res));
    dispatch(setContact({ isLoading: false, response: result.data }));
  } catch (error) {
    dispatch(setContact({ isLoading: false }));
  }
};

export const setSalesAsync = (body, callback) => async (dispatch) => {
  try {
    dispatch(setSales({ isLoading: true }));
    var URL = `${process.env.REACT_APP_BASE_URL}${config.SET_SALES}`;
    const result = await postAPICall(URL, body).then((res) => callback(res));
    dispatch(setSales({ isLoading: false, response: result.data }));
  } catch (error) {
    dispatch(setSales({ isLoading: false }));
  }
};

export const getContactsAsync = (body) => async (dispatch) => {
  try {
    dispatch(getContacts({ isLoading: true }));
    var URL = `${process.env.REACT_APP_BASE_URL}${config.CONTACTS}`;
    const result = await getAPICall(URL, body);
    dispatch(getContacts({ isLoading: false, response: result.data }));
  } catch (error) {
    dispatch(getContacts({ isLoading: false }));
  }
};

export const { setContact, getContacts, setSales, reset } =
  ContactSlice.actions;

export const setContactResponse = (state) => state.contact.setContactResponse;
export const setSalesResponse = (state) => state.contact.setSaleResponse;
export const getContactsResponse = (state) => state.contact.getContacts;

export default ContactSlice.reducer;
