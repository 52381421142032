import React, { useEffect, useState } from "react";
import superpower from "../../assets/img/superpower.png";
import counterfiet_big from "../../assets/img/counterfiet_big.svg";
import custumer_trust from "../../assets/img/custumer_trust.svg";
import hussale_free from "../../assets/img/hussale_free.svg";
import tickCircle from "../../assets/img/tick-circle.svg";
import realtime_tracking from "../../assets/img/realtime_tracking.svg";
import repoting from "../../assets/img/repoting.svg";
import customization from "../../assets/img/customization.svg";
import data_integration from "../../assets/img/data_integration.svg";
import client_automated from "../../assets/img/client_automated.svg";
import automated_detection from "../../assets/img/automated_detection.svg";
import custumer_verification from "../../assets/img/custumer_verification.svg";
import delivering_success from "../../assets/img/delivering_success.svg";
import user from "../../assets/img/user.png";
import linkedin_2 from "../../assets/img/linkedin_2.svg";
import Facebook_2 from "../../assets/img/Facebook_2.svg";
import Instagram_2 from "../../assets/img/Instagram_2.svg";
import twitter_2 from "../../assets/img/twitter_2.svg";
import counterfiet_2 from "../../assets/img/counterfiet_2.svg";
import years from "../../assets/img/years.svg";
import client_count from "../../assets/img/client_count.svg";
import user_count from "../../assets/img/user_count.svg";
import { Button } from "react-bootstrap";
import Ripples from "react-ripples";
import { useSelector, useDispatch } from "react-redux";
import { ColorRing } from "react-loader-spinner";
import {
  getAboutusProfessionalsAsync,
  getAboutProfessionalsResponse,
  getCountAsync,
  getCountResponse,
} from "../../service/slices/aboutUsSlice";
import CountUp, { useCountUp } from "react-countup";

function Faq(props) {
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingCount, setIsLoadingCount] = useState(false);
  const [getCountData, setGetCountData] = useState({});
  const [getProfessionalsData, setGetProfessionalsData] = useState([]);
  const dispatch = useDispatch();
  const _getCountResponse = useSelector(
    (state) => state?.aboutus?.getCountResponse
  );
  const _getAboutProfessionalsResponse = useSelector(
    (state) => state?.aboutus?.getAboutProfessionalsResponse
  );

  useEffect(() => {
    // setIsLoading(true);
    setIsLoadingCount(true);
    dispatch(getCountAsync());
    dispatch(getAboutusProfessionalsAsync());
  }, []);

  useEffect(() => {
    if (_getCountResponse?.response?.status == 200) {
      setIsLoadingCount(_getCountResponse?.isLoading);
      setGetCountData(_getCountResponse?.response?.data);
    }
  }, [_getCountResponse]);

  useEffect(() => {
    if (_getAboutProfessionalsResponse?.response?.status == 200) {
      setIsLoading(_getAboutProfessionalsResponse?.isLoading);
      setGetProfessionalsData(_getAboutProfessionalsResponse?.response?.data);
    }
  }, [_getAboutProfessionalsResponse]);

  console.log(_getAboutProfessionalsResponse, "::::");

  useCountUp({
    ref: "counter",
    // enableScrollSpy: true,
    scrollSpyOnce: true,
    scrollSpyDelay: 1500,
    duration: 5,
  });
  return (
    <>
      <section className="aboutus_wrapper faq_wrapper">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <h2
                className="heading wow fadeInUp mb-0"
                data-wow-duration=".70s"
                style={{ marginBottom: "0px!important" }}
              >
                FAQ
              </h2>
            </div>
          </div>

          <div className="row">
            <div
              className="col-sm-12 col-md-12 col-lg-12 col-xl-12"
              style={{ marginBottom: "0" }}
            >
              <div
                className="innerbox_counterfiet pt-0"
                style={{ paddingTop: "0px!important" }}
              >
                <h3
                  className="sub_heading mb-3 wow fadeInLeft"
                  data-wow-duration=".70s"
                >
                  1. How can I create an account on Clarity Client?
                </h3>
                <p
                  className="sub_para mr-2rem mb-0 wow fadeInLeft"
                  data-wow-duration=".70s"
                  style={{ width: "100%", maxWidth: "100%", margin: "0" }}
                >
                  To establish an account with Clarity Client, kindly visit
                  [this URL](#) and follow the stepby-step instructions provided
                  on our account creation form. Ensure you fill in the necessary
                  details accurately to ensure a seamless registration process.
                  If you encounter any challenges, please don’t hesitate to
                  reach out to our support team for assistance.
                </p>
              </div>

              <div
                className="innerbox_counterfiet pt-0"
                style={{ paddingTop: "0px!important" }}
              >
                <h3
                  className="sub_heading mb-3 wow fadeInLeft"
                  data-wow-duration=".70s"
                >
                  2. Can I customize the Clarity Client portal with my own
                  branding elements?
                </h3>
                <p
                  className="sub_para mr-2rem mb-0 wow fadeInLeft"
                  data-wow-duration=".70s"
                  style={{ width: "100%", maxWidth: "100%", margin: "0" }}
                >
                  Certainly! The Clarity Client portal is designed with our
                  users in mind. We offer an intuitive option that allows you to
                  rebrand your profile, ensuring that whenever your customers
                  scan a QR code associated with your products, they are greeted
                  with your distinct brand imagery and identity. This feature
                  aims to enhance the consistency and professionalism of your
                  brand's presence across all touchpoints. If you need guidance
                  on how to customize your branding elements, our support team
                  is always available to assist.
                </p>
              </div>

              <div
                className="innerbox_counterfiet pt-0"
                style={{ paddingTop: "0px!important" }}
              >
                <h3
                  className="sub_heading mb-3 wow fadeInLeft"
                  data-wow-duration=".70s"
                >
                  3. Is it possible to set up a unique domain for my company's
                  staff members?
                </h3>
                <p
                  className="sub_para mr-2rem mb-0 wow fadeInLeft"
                  data-wow-duration=".70s"
                  style={{ width: "100%", maxWidth: "100%", margin: "0" }}
                >
                  Absolutely! Upon registering with Clarity Codes, one of the
                  features we offer is the ability to establish a dedicated
                  subdomain for your company. This ensures that your staff
                  members, managers, or any other designated personnel can
                  access the admin portal seamlessly via a domain that reflects
                  your brand or company name. This not only reinforces brand
                  consistency but also provides an easy-to-remember access point
                  for your team. Should you need assistance with the setup
                  process, our support team is readily available to guide you.
                </p>
              </div>

              <div
                className="innerbox_counterfiet pt-0"
                style={{ paddingTop: "0px!important" }}
              >
                <h3
                  className="sub_heading mb-3 wow fadeInLeft"
                  data-wow-duration=".70s"
                >
                  4. How many batches can I export at once?
                </h3>
                <p
                  className="sub_para mr-2rem mb-0 wow fadeInLeft"
                  data-wow-duration=".70s"
                  style={{ width: "100%", maxWidth: "100%", margin: "0" }}
                >
                  You can export a maximum of xxx batches in a single operation.
                  Ensure your system meets the recommended requirements for
                  optimal performance.
                </p>
              </div>

              <div
                className="innerbox_counterfiet pt-0"
                style={{ paddingTop: "0px!important" }}
              >
                <h3
                  className="sub_heading mb-3 wow fadeInLeft"
                  data-wow-duration=".70s"
                >
                  5. Are all details from mobile app users accurate after
                  reporting a counterfeit request?
                </h3>
                <p
                  className="sub_para mr-2rem mb-0 wow fadeInLeft"
                  data-wow-duration=".70s"
                  style={{ width: "100%", maxWidth: "100%", margin: "0" }}
                >
                  While users have the capability to specify the exact purchase
                  location and provide photos of both the product and receipt,
                  the accuracy of the details primarily depends on the
                  information input by the user. However, we maintain that user
                  details are consistently accurate as updated information is a
                  prerequisite for utilizing our app.
                </p>
              </div>

              <div
                className="innerbox_counterfiet pt-0"
                style={{ paddingTop: "0px!important" }}
              >
                <h3
                  className="sub_heading mb-3 wow fadeInLeft"
                  data-wow-duration=".70s"
                >
                  6. What details will be visible to my customers when they scan
                  my product?
                </h3>
                <p
                  className="sub_para mr-2rem mb-0 wow fadeInLeft"
                  data-wow-duration=".70s"
                  style={{ width: "100%", maxWidth: "100%", margin: "0" }}
                >
                  Upon scanning, customers will be presented with the product
                  image, its name, manufacturing location, expiry date, unique
                  product code, and a concise product description. This ensures
                  transparency and provides essential information to the end
                  user.
                </p>
              </div>

              <div
                className="innerbox_counterfiet pt-0"
                style={{ paddingTop: "0px!important" }}
              >
                <h3
                  className="sub_heading mb-3 wow fadeInLeft"
                  data-wow-duration=".70s"
                >
                  7. Is there an alert system in place to track or prevent
                  counterfeit products?
                </h3>
                <p
                  className="sub_para mr-2rem mb-0 wow fadeInLeft"
                  data-wow-duration=".70s"
                  style={{ width: "100%", maxWidth: "100%", margin: "0" }}
                >
                  Indeed, our cutting-edge technology is designed to detect
                  duplicated codes. Should such an issue arise, the system will
                  immediately notify the admin, allowing you to investigate the
                  specific code in question.
                </p>
              </div>

              <div
                className="innerbox_counterfiet pt-0"
                style={{ paddingTop: "0px!important" }}
              >
                <h3
                  className="sub_heading mb-3 wow fadeInLeft"
                  data-wow-duration=".70s"
                >
                  8. Can I monitor my product inventory?
                </h3>
                <p
                  className="sub_para mr-2rem mb-0 wow fadeInLeft"
                  data-wow-duration=".70s"
                  style={{ width: "100%", maxWidth: "100%", margin: "0" }}
                >
                  Currently, Clarity Codes does not offer a feature to track
                  inventory. We are always looking to enhance our platform, so
                  please stay tuned for future updates.
                </p>
              </div>

              <div
                className="innerbox_counterfiet pt-0"
                style={{ paddingTop: "0px!important" }}
              >
                <h3
                  className="sub_heading mb-3 wow fadeInLeft"
                  data-wow-duration=".70s"
                >
                  9. What's the duration of the free plan on Clarity Client?
                </h3>
                <p
                  className="sub_para mr-2rem mb-0 wow fadeInLeft"
                  data-wow-duration=".70s"
                  style={{ width: "100%", maxWidth: "100%", margin: "0" }}
                >
                  To introduce new users to our platform, we generously offer 50
                  codes free of charge. This allows you to gain firsthand
                  experience with how our product operates.
                </p>
              </div>

              <div
                className="innerbox_counterfiet pt-0"
                style={{ paddingTop: "0px!important" }}
              >
                <h3
                  className="sub_heading mb-3 wow fadeInLeft"
                  data-wow-duration=".70s"
                >
                  10. Are custom subscription plans available for clients?
                </h3>
                <p
                  className="sub_para mr-2rem mb-0 wow fadeInLeft"
                  data-wow-duration=".70s"
                  style={{ width: "100%", maxWidth: "100%", margin: "0" }}
                >
                  Absolutely. For businesses with specific requirements, we
                  offer enterprise solutions. Kindly get in touch with our
                  customer success team, and we'll tailor a package based on
                  your needs.
                </p>
              </div>

              <div
                className="innerbox_counterfiet pt-0"
                style={{ paddingTop: "0px!important" }}
              >
                <h3
                  className="sub_heading mb-3 wow fadeInLeft"
                  data-wow-duration=".70s"
                >
                  11. How do I change my login password for Clarity Client
                  account?
                </h3>
                <p
                  className="sub_para mr-2rem mb-0 wow fadeInLeft"
                  data-wow-duration=".70s"
                  style={{ width: "100%", maxWidth: "100%", margin: "0" }}
                >
                  In the just few simple steps, kindly visit [this URL] and
                  enter your mail to get the instruction of change password on
                  your email.
                </p>
              </div>

              <div
                className="innerbox_counterfiet pt-0"
                style={{ paddingTop: "0px!important" }}
              >
                <h3
                  className="sub_heading mb-3 wow fadeInLeft"
                  data-wow-duration=".70s"
                >
                  12. Is it possible to delete my Clarity Client account?
                </h3>
                <p
                  className="sub_para mr-2rem mb-0 wow fadeInLeft"
                  data-wow-duration=".70s"
                  style={{ width: "100%", maxWidth: "100%", margin: "0" }}
                >
                  Yes, should you decide that our product or service isn't a fit
                  for your needs, you can conveniently delete your account from
                  the admin panel.
                </p>
              </div>

              <div
                className="innerbox_counterfiet pt-0"
                style={{ paddingTop: "0px!important" }}
              >
                <h3
                  className="sub_heading mb-3 wow fadeInLeft"
                  data-wow-duration=".70s"
                >
                  13. Could you elaborate on your refund policy?
                </h3>
                <p
                  className="sub_para mr-2rem mb-0 wow fadeInLeft"
                  data-wow-duration=".70s"
                  style={{ width: "100%", maxWidth: "100%", margin: "0" }}
                >
                  We prioritize customer satisfaction, but please note that we
                  currently do not offer refunds. It's always recommended to
                  review our terms and conditions for a comprehensive
                  understanding.
                </p>
              </div>

              <div
                className="innerbox_counterfiet pt-0"
                style={{ paddingTop: "0px!important" }}
              >
                <h3
                  className="sub_heading mb-3 wow fadeInLeft"
                  data-wow-duration=".70s"
                >
                  14. Could you share more about your support policy?
                </h3>
                <p
                  className="sub_para mr-2rem mb-0 wow fadeInLeft"
                  data-wow-duration=".70s"
                  style={{ width: "100%", maxWidth: "100%", margin: "0" }}
                >
                  For a detailed outline of our support policy, please refer to
                  [this link](#).
                </p>
              </div>

              <div
                className="innerbox_counterfiet pt-0"
                style={{ paddingTop: "0px!important" }}
              >
                <h3
                  className="sub_heading mb-3 wow fadeInLeft"
                  data-wow-duration=".70s"
                >
                  15. How does Clarity Client ensure the security of my data?
                </h3>
                <p
                  className="sub_para mr-2rem mb-0 wow fadeInLeft"
                  data-wow-duration=".70s"
                  style={{ width: "100%", maxWidth: "100%", margin: "0" }}
                >
                  Ensuring the security of your data is paramount to Clarity
                  Codes. We utilize advanced encryption methods, adhere to
                  strict data protection regulations, and conduct periodic
                  security audits. Rest assured, your information remains
                  confidential and protected against any unauthorized access or
                  breaches.
                </p>
              </div>

              <div
                className="innerbox_counterfiet pt-0"
                style={{ paddingTop: "0px!important" }}
              >
                <p
                  className="sub_para mr-2rem mb-0 wow fadeInLeft sub_para_two"
                  data-wow-duration=".70s"
                  style={{ width: "100%", maxWidth: "100%", margin: "0" }}
                >
                  (Note: The link placeholder in question 14 should be replaced
                  with the actual link to the support policy.)
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Faq;
