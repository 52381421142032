import React, { useEffect, useState, useRef } from "react";
import { Link, useSearchParams } from "react-router-dom";
import Scrollspy from "react-scrollspy";
import goback from "../../assets/img/goback.svg";
import bg_gradient from "../../assets/img/bg_gradient.svg";
import { ColorRing } from "react-loader-spinner";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import $ from "jquery";
import {
  getAccountManagementAsync,
  getAccountManagementResponse,
} from "../../service/slices/accountManagementSlice";
import { useDispatch, useSelector } from "react-redux";

function AccountManagementDetail() {
  const [isLoading, setIsLoading] = useState(false);
  const contentRef = useRef(null);
  const [isFooterArrived, setisfooterarrived] = useState(false);
  const navigate = useNavigate();
  const handleClickBack = () => {
    navigate(-1);
  };
  const [searchParams] = useSearchParams();
  let id = searchParams.get("id");
  let topicId = searchParams.get("topicId");
  const _getAccountManagementResponse = useSelector(
    getAccountManagementResponse
  );
  const dispatch = useDispatch();
  const [decodedData, setDecodeData] = useState([]);
  const [headings, setHeadings] = useState([]);
  const [topicName, setTopicName] = useState("");

  useEffect(() => {
    if (_getAccountManagementResponse?.response?.status === 200) {
      const data = _getAccountManagementResponse?.response?.data;
      const m = data.find((item) => item.moduleId === id);
      const t = m.topics.find((item) => item._id === topicId);
      if (t) {
        const details = t.description;
        setTopicName(t.topicName)
        const decodeData = details;
        const parser = new DOMParser();
        const doc = parser.parseFromString(decodeData, "text/html");
        const h1Elements = doc.querySelectorAll("h1");
        findHeadings(h1Elements);
        let count = 0;
        let dataArr = decodeData?.split("<h1>").map((val, i) => {
          if (val.includes("h1")) {
            count++;
            return `<h1 id="section-${count}">${val}`;
          }
          return val;
        });
        setDecodeData(dataArr);
      }
      setIsLoading(false);
    }
  }, [_getAccountManagementResponse]);

  useEffect(() => {
    if (id && topicId) {
      dispatch(getAccountManagementAsync());
    }
  }, [id, topicId]);

  const scrollToSection = (id) => {
    const $element = $("#" + id);
    if ($element.length) {
      console.log($element);
      $("html, body").animate(
        {
          scrollTop: $element.offset().top - 150,
        },
        800
      );
    }
  };
  $(window).scroll(function () {
    var scrollPosition = $(window).scrollTop();
    var bodyHeight = $("body").height();
    var footerHeight = 1400;

    const activeLi = $("li.isCurrent");
    if (activeLi.length > 0) {
      const nextLi = activeLi.next("li");
      if (nextLi.length > 0) {
        const scrollPosition =
          nextLi.offset().top - $("ul").offset().top - nextLi.height();
        $("ul").scrollTop($("ul").scrollTop() + scrollPosition);
      }
    }
    // console.log(bodyHeight - 444);
    console.log(scrollPosition, "scroll position", bodyHeight, "body heght");
    if (scrollPosition > bodyHeight - footerHeight) {
      console.log("hello");
      setisfooterarrived(true);
    } else {
      setisfooterarrived(false);
    }
  });

  useEffect(() => {
    if (isFooterArrived) {
      console.log("hello");
      $(".sidebar_wrapper").css({ position: "static" });
      $(".contant").css({ "margin-left": "0em" });
    } else {
         $(".sidebar_wrapper").css({ position: "" });
      $(".contant").css({ "margin-left": "30em" });
      console.log("hello again");
    }
  }, [isFooterArrived]);




  

  const findHeadings = (h1Elements) => {
    let headingList = [];
    h1Elements.forEach((h1Element, index) => {
      headingList.push({
        text: h1Element.textContent.trim(),
        id: `section-${index + 1}`,
      });
    });
    setHeadings([...headingList]);
  };

  return (
    <>
      {!isLoading ? (
        <section className="termcondition_page">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <div className="scroll_wrapper">
                  <div className="sidebar_wrapper">
                    <div className="row">
                      <div className="col-md-12">
                        <Link
                          className="goback wow fadeInUp"
                          data-wow-duration=".50s"
                          onClick={handleClickBack}
                        >
                          <img src={goback} alt="Go Back" /> Go Back
                        </Link>
                        <h2
                          className="heading mb-4 wow fadeInUp"
                          data-wow-duration=".60s"
                        >
                          {topicName}
                        </h2>
                      </div>
                    </div>

                    <div
                      className="sidebar wow fadeInUp"
                      data-wow-duration=".70s"
                    >
                      <Scrollspy
                          className="scrollspy"
                          items={headings.map((heading) => heading.id)}
                          currentClassName="isCurrent"
                        >
                          {headings.map((value) => (
                            <li key={value.id}>
                              <Link
                                to={`#${value.id}`}
                                onClick={() => scrollToSection(value.id)}
                              >
                                {value.text}
                              </Link>
                            </li>
                          ))}
                        </Scrollspy>
                    </div>
                  </div>
                  <div className="contant" ref={contentRef}>
                    {decodedData.map((value, i) => {
                      return (
                        <>
                          <div
                            dangerouslySetInnerHTML={{ __html: value }}
                          ></div>
                        </>
                      );
                    })}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <img
            className="bg_gradient"
            src={bg_gradient}
            alt="Background Gradient"
          />
        </section>
      ) : (
        <div
          style={{
            height: "100vh",
            width: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <ColorRing
            visible={true}
            height="80"
            width="80"
            ariaLabel="blocks-loading"
            wrapperStyle={{}}
            wrapperClass="blocks-wrapper"
            colors={["#004ac3", "#346fcf", "#528ae6", "#346fcf", "#004ac3"]}
          />
        </div>
      )}
    </>
  );
}

export default AccountManagementDetail;
