import React from "react";
import { Accordion } from "react-bootstrap";
function AccordionBox(props) {
  const { faqsData } = props;
  return (
    <div className="accordian_wrapper">
      <Accordion defaultActiveKey="0">
        {faqsData?.map((faq, i) => {
          return (
            <Accordion.Item eventKey={i}>
              <Accordion.Header>{faq?.question}</Accordion.Header>
              <Accordion.Body>{faq?.answer}</Accordion.Body>
            </Accordion.Item>
          );
        })}
      </Accordion>
    </div>
  );
}

export default AccordionBox;
